<div id="video-search-header">
        <div class="container">
            
            <div id="video-search-header-filtering">
                <form id="video-search-header-filtering-padding" (ngSubmit)="btSearch()">
                    <input type="text" placeholder="{{'search.Placeholder' | transloco}}" aria-label="Search" id="main-text-field" ngbAutofocus name="text" [(ngModel)]="model.texto">
                    <div class="row">
                        <div class="col-sm extra-padding">
                            <div class="dotted-dividers-pro ms-4">
                                <h5>{{'search.Type' | transloco}}:</h5>
                                <ul class="video-search-type-list">
                                    <li>
                                        <label class="checkbox-pro-container">{{'search.Teatro' | transloco}}
                                          <input type="checkbox" id="movies-type-teatro"  name="teatro" [(ngModel)]="model.teatro">
                                          <span class="checkmark-pro"></span>
                                        </label>
                                    </li>
                                    <li>
                                        <label class="checkbox-pro-container">{{'search.Danca' | transloco}}
                                          <input type="checkbox" id="movie-type-danca" name="danca" [(ngModel)]="model.danca">
                                          <span class="checkmark-pro"></span>
                                        </label>
                                    </li>
                                    <li>
                                        <label class="checkbox-pro-container">{{'search.Cinema' | transloco}}
                                          <input type="checkbox" id="movies-type-cinema"  name="cinema" [(ngModel)]="model.cinema">
                                          <span class="checkmark-pro"></span>
                                        </label>
                                    </li>
                                </ul><div class="clearfix"></div>
    
                            </div>
                        </div>
                        <div class="col-sm extra-padding">
                            <div class="dotted-dividers-pro ms-4">
                            <h5>{{'search.Genre' | transloco}}:</h5>
                            <select class="custom-select"  name="genero" [(ngModel)]="model.genero" style="min-width: 200px;">
                              <option [ngValue]="null" selected>{{'search.AllGenre' | transloco}}</option>
                              <option *ngFor="let opt of generos" value="{{opt.generoId}}">{{opt.descricao}}</option>
                            </select>
                            </div>
                        </div>
                        <div class="col-sm extra-padding extra-range-padding">
                            <div class="dotted-dividers-pro ms-4">
                            <h5>{{'search.Duration' | transloco}}:</h5>
                            <select class="custom-select"  name="duracao" [(ngModel)]="model.duracao" style="min-width: 200px;">
                              <option [ngValue]="null" selected>{{'search.AllDuration' | transloco}}</option>
                              <option *ngFor="let opt of duracoes" value="{{opt.duracaoId}}">{{opt.descricao}}</option>
                            </select>
                            </div>
                        </div>
                        <!-- <div class="col-sm extra-padding extra-range-padding">
                            <h5>{{'search.AverageClassification' | transloco}}:</h5>
                            <div class="range-padding-top"><input class="range-example-rating-input" type="text" min="0" max="10" value="4,10" step="1" /></div>
                        </div> -->
                    </div>
                    <div id="video-search-header-buttons">
                        <a class="btn my-0 py-1 ms-0 me-2 px-0" style="min-width: 180px;"><input type="submit" class="m-0 p-0 border-0" value="{{'search.SearchVideos' | transloco}}"> </a>
                        <input type="reset" class="btn reset-btn my-0 py-1" value="{{'search.Reset' | transloco}}"> 
                    </div>
                </form>
            </div>

            <!-- model: {{model | json }} -->
        </div>
    </div>
