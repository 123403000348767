import { AfterViewInit, Component, HostListener, Injectable } from '@angular/core';
import { encode } from 'base64-arraybuffer';
import { ConfigurationService as LibConfigurationService, WebApiService } from "libs/vg-common";
import { ConfigurationService } from './services/configuration.service';
import { saveAs } from 'file-saver-es';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements AfterViewInit {
  public title = 'WebSite';
  public apiServerUrl = null;
  public debugMode = false;
  public isRegistingNewsletter = false;
  public isInicializing = true;
  public hideTop = true;

  public contentArrayBuffer: any;
  public filename = '';

  public emailSubscribed = null;

  // @HostListener('document:keydown', ['$event'])
  // handleKeyboardEvent(event: KeyboardEvent) { 
  //   // this.key = event.key;
  //   console.log('AppComponent.keydown');

  // }

  // LC: JUL 2022:  configurationService => starts this service that begins the initialization of the app, do not remove
  constructor(public configurationService: ConfigurationService, private libConfigurationService: LibConfigurationService, private webApiService: WebApiService) {
    console.log('AppComponent CTOR');

    this.libConfigurationService.configurationSubscribe((conf: any) => {
      this.debugMode = conf?.debugMode;
    });
  }

  ngAfterViewInit() {
    setTimeout(() => {
      this.isInicializing = false;
    }, 500);
  }
  
  public newsletterSubscribe = (email: any) => {
    if (email.value) {
      console.log('email subscribe', email.value);

      this.isRegistingNewsletter = true;
      this.webApiService.registerNewsletter(email.value).subscribe((ret:boolean) => {
        this.isRegistingNewsletter = false;

        this.emailSubscribed = email.value;
      }, (error:any) => {
        this.isRegistingNewsletter = false;
      });

    }
  }

  public onAddDocument = (event: any) => {
    const files: FileList = (event as any).target.files;
    const file: File = files[0];


    const fileReader = new FileReader();
    fileReader.onloadend = (e) => {
      const bytes = fileReader.result;
      this.filename = file.name;
      this.contentArrayBuffer = (bytes as ArrayBuffer);
    };
    fileReader.readAsArrayBuffer(file);
  }

  public uploadFile = () => {
    // console.log('uploadFile', this.filename, this.contentArrayBuffer);

    this.webApiService.uploadFile({ filename: this.filename, contentArrayBufferBase64: encode(this.contentArrayBuffer) }).subscribe(ret => {
      const a = 1;
      console.log('file-uploaded');
    });
  }

  public downloadFile(documentId: number): void {
    // uploaded, get from server
    this.webApiService
      .getFile(documentId)
      .subscribe((data: any) => {
        saveAs(data.content, data.filename);
      },
        (error: any) => console.log(error),
        () => {
          console.log('Download:: Get Document complete');
        });
  }

}


