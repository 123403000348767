import { Input } from '@angular/core';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ComponentLookup, DataSourceService, WebApiService } from 'libs/vg-common';
import { ConfigurationService } from 'src/app/services/configuration.service';

@Component({
  selector: 'app-vayvo-resultados',
  templateUrl: './vayvo-resultados.component.html',
  styleUrls: ['./vayvo-resultados.component.scss']
})
@ComponentLookup('VayvoResultadosComponent')
export class VayvoResultadosComponent implements OnInit {
  @Input() public config: any = null;

  public results: any = null;
  public isSearching = false;

  constructor(private router: Router, private activatedRoute: ActivatedRoute, private webApiService: WebApiService, private configurationService: ConfigurationService, private dataSourceService: DataSourceService) { }

  ngOnInit(): void {
    // get search params
    this.activatedRoute.queryParams.subscribe(params => {

      const p = params['filter'];
      const qs = decodeURIComponent(p);
      const filter = JSON.parse(qs);
      // console.log('filter', filter);

      this.isSearching = true;
      this.dataSourceService.getData('conteudoSearch', filter).subscribe(data => {
         this.results = data;

         if (this.results?.lista) {
          // get images
          this.results.lista.forEach((element: any) => {
            if (element.backgroundImageId) {
              // get the file from the server / or cache
              if (this.configurationService.isPlatformBrowser) {
                this.webApiService.getImageFile(element.backgroundImageId, 32000, 350).subscribe((data: any) => {
                  element.backgroundImage = data.contentArrayBufferBase64;
                });
              }
            }
          });
        }

        this.isSearching = false;
       }, error => {
        this.isSearching = false;
       });
    });
  }

  public getTruncate = (texto: string, length: number) => {
    const t = texto.replace(/(<([^>]+)>)/ig, '')
    return t.substring(0, Math.min(length, t.length)) + ' ...';
  }

  public getImage = (element: any) => {

    if (element && element.backgroundImageId) {
      if (element.backgroundImage)
        return ('data:image/jpeg;base64,' + element.backgroundImage);
      else
        return '';
    } else
      return 'assets/images/Gray1700x1133.png';
  }

  public xClick = (iten: any) => {
    this.router.navigateByUrl('/conteudo/' + iten.route + '/1/1').then(() => {
      if (this.configurationService.isPlatformBrowser) {
        // window.location.reload();
      }
    });
  }

}
