import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';

import { throwError as observableThrowError, Observable } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import { decode } from 'base64-arraybuffer';

import { ConfigurationService } from './configuration.service';
import { FileInfo } from '../model/generic/fileInfo';
import { ApplicationError } from '../model/generic/applicationError';

/// object return from webApi
class ApiReturn {
  public value: any;
  public errorCode?: string;
  public errorMessage?: string;
}

@Injectable()
export class HttpHelperService {

  private headers: HttpHeaders = new HttpHeaders();
  private options: any;

  // tslint:disable-next-line: no-string-literal
  //protected oAuthToken: string = window['vgOAuthToken'];    // => global var set by the asp.net App.aspx page

  protected oAuthToken: string = '';
  protected language = 'pt';

  constructor(protected http: HttpClient, protected configuration: ConfigurationService) {
    this.oAuthToken = configuration.getOAuthToken() || this.oAuthToken;
    this.language = configuration.getLanguage() || this.language;

    this.setHeaders(this.oAuthToken);
    // this.headers = new HttpHeaders()
    //   .set('Content-Type', 'application/json')
    //   .set('Accept', 'application/json')
    //   .set('Application', configuration.appConfig?.applicationModule ?? '')
    //   .set('Authorization', 'Bearer ' + this.oAuthToken);
    // this.options = { headers: this.headers };

    configuration.OAuthTokenSubscribe((data: string) => {

      // console.log('HttpHelperService::data', data);

      if (data !== this.oAuthToken && (configuration.appConfig?.applicationModule !== 'Backoffice' || data)) { // && data) {
        this.oAuthToken = data;

        this.headers = new HttpHeaders()
          .set('Content-Type', 'application/json')
          .set('Accept', 'application/json')
          .set('Language', this.language);

        if (this.oAuthToken) {
          // console.log('ctor: (TOK) TOK OK');
          this.headers = this.headers.append('Authorization', 'Bearer ' + this.oAuthToken);
        }

        this.options = { headers: this.headers };
      }
      // console.log('ctor: (TOK)' + (this.headers as any).headers.size);
    });

    configuration.LanguageSubscribe((data: string) => {
      // console.log('httpH:', data)
      if (this.language !== data) {
        this.language = data;

        this.setHeaders(this.oAuthToken);

        // this.headers = new HttpHeaders()
        //   .set('Content-Type', 'application/json')
        //   .set('Accept', 'application/json')
        //   .set('Language', this.language);

        // if (this.oAuthToken) {
        //   this.headers = this.headers.append('Authorization', 'Bearer ' + this.oAuthToken);
        // }

        // this.options = { headers: this.headers };
        // console.log('ctor: (LG)' + (this.headers as any).headers.size);
      }
    });
  }

  public SetToken(oAuthToken: string) {
    this.setHeaders(oAuthToken);
  }

  // public SetLanguage(language: string) {
  //   this.language = language;
  //   this.setHeaders(this.oAuthToken);
  // }

  private setHeaders(oAuthToken: string) {
    // console.log('setHeaders', this.language);
    this.headers = new HttpHeaders()
      .set('Content-Type', 'application/json')
      .set('Accept', 'application/json')
      .set('Language', this.language)
      .set('Application', this.configuration.appConfig?.applicationModule ?? '');

      if (oAuthToken) this.headers = this.headers.append('Authorization', 'Bearer ' + oAuthToken);

    this.options = { headers: this.headers };
  }

  public getToken() {
    return this.oAuthToken;
  }

  /**
   * Execute GET request on Server API to Json Types
   */
  public httpGet: any = (controllerName: string, actionName: string, params?: any): Observable<any> => {
    this.setHeaders(this.oAuthToken);

    // console.log('httpGet, this.oAuthToken', this.oAuthToken);

    const fullUrl = `${this.configuration.appConfig.server}/api/${controllerName}/${actionName}`;
    // console.log('httpGet: options', this.options);
    // console.log('httpGet: fullUrl', fullUrl);
    return this.http
      .get(fullUrl, this.options)
      .pipe(
        map(response => {
          const ret: ApiReturn = response as any;
          if (!ret.errorCode && !ret.errorMessage) {
            return ret.value;
          } else {  // aplication error
            throw new ApplicationError(ret.errorCode, ret.errorMessage);
          }
        })
        , catchError((err: any, caught: any) => {
          console.log('httpGet: fullUrl', fullUrl);
          return this.handleError(err, caught);
        })
      );
  }

  /**
   * Execute GET request on Server API to GetFiles
   */
  public httpGetFile: any = (controllerName: string, actionName: string, params?: any): Observable<FileInfo> => {
    this.setHeaders(this.oAuthToken);
    const fullUrl = `${this.configuration.appConfig.server}/api/${controllerName}/${actionName}`;
    return this.http
      .get(fullUrl, this.options)
      .pipe(
        map(response => {
          const ret: ApiReturn = response as any;
          if (!ret.errorCode && !ret.errorMessage) {
            const myData = ret.value;
            const myFileInfo = new FileInfo();
            myFileInfo.filename = (myData as any).filename;
            myFileInfo.content = new Blob([decode((myData as any).content)], { type: (myData as any).contentType });
            myFileInfo.contentArrayBufferBase64 = myData.content;
            return myFileInfo;
          } else {  // aplication error
            throw new ApplicationError(ret.errorCode, ret.errorMessage);
          }
        })
        , catchError(this.handleError)
      );
  }

  /**
   * Execute POST request on Server API
   */
  public httpPost: any = (controllerName: string, actionName: string, data: any): Observable<any> => {
    this.setHeaders(this.oAuthToken);
    const fullUrl = `${this.configuration.appConfig.server}/api/${controllerName}/${actionName}`;
    return this.http
      .post(fullUrl, data, this.options)
      .pipe(
        map(response => {
          const ret: ApiReturn = response as any;
          if (!ret.errorCode && !ret.errorMessage) {
            return ret.value;
          } else {  // aplication error
            throw new ApplicationError(ret.errorCode, ret.errorMessage);
          }
        })
        , catchError(this.handleError)
      );
  }

  protected handleError(err: any, caught: any) {
    let errMsg = '';

    console.log('err', err);

    if (err instanceof ApplicationError) {
      return observableThrowError(err);
    }

    try {
      const errorObject = JSON.parse(err._body);
      if (errorObject) {
        if (errorObject.error) {
          errMsg = errorObject.error + ' - ' + errorObject.error_description;
        } else if (errorObject.exceptionMessage) {
          errMsg = errorObject.exceptionMessage;
        }
      }
      return observableThrowError(errMsg);
    } catch (error) {
    }

    if (err.error instanceof Error) {
      errMsg = 'An error occurred:' + err.error.message;
    } else {
      errMsg = `Backend returned code ${err.status}, body was: ${err.error}`;
    }

    console.error(errMsg);
    return observableThrowError(errMsg);
  }
}
