import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-vayvo-conteudos-lista',
  templateUrl: './vayvo-conteudos-lista.component.html',
  styleUrls: ['./vayvo-conteudos-lista.component.scss']
})
export class VayvoConteudosListaComponent implements OnInit {
  @Input() config: any;
  @Input() lista: any[] = [];

  constructor() { }

  ngOnInit(): void {
  }

  public getImage = (element: any) => {

    if (element && element.backgroundImageId) {
      if (element.backgroundImage)
        return ('data:image/jpeg;base64,' + element.backgroundImage);
      else
        return '';
    } else
      return 'assets/images/Gray1700x1133.png';
  }
}
