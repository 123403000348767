/*
    LC: 10.Jan.2018
    Error Object, for Server side ApplicationExceptions

    ver: https://github.com/Microsoft/TypeScript/issues/13965
*/

// Use this class to correct the prototype chain.
export class ApplicationError extends Error {
    __proto__: Error;
    constructor(errorCode?: string, message?: string) {
        const trueProto = new.target.prototype;
        super(message);
        this.errorCode = errorCode;

        // Alternatively use Object.setPrototypeOf if you have an ES6 environment.
        this.__proto__ = trueProto;
    }

    public systemError?: boolean;
    public errorCode?: string;
}
