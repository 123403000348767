import { ModuleWithProviders, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { TranslocoRootLibModule } from './transloco-root.module';

import { ConfigurationService } from './services/configuration.service';
import { JsonConfigService } from './services/json-config.service';
import { HttpHelperService } from './services/http-helper.service';
import { DynamicRoutesComponent } from './components/dynamic-routes/dynamic-routes.component';
import { GenericContentComponent } from './components/generic-content/generic-content.component';
import { SliderComponent } from './components/slider/slider.component';
import { DataSourceService } from './services/data-source.service';
import { DynamicComponentsService } from './components/dynamic-component/component-lookup';
import { ComponentHostDirective } from './directives/component-host/componentHost.directive';
import { NotFoundComponent } from './components/not-found/not-found.component';
import { GenericCellComponent } from './components/generic-cell/generic-cell.component';
import { WebApiService } from './services/web-api.service';
import { CanActivateNavigation, SecurityService } from './services/security.service';
import { SecurityDataService } from './services/security-data.service';
import { LoginComponent } from './components/login/login.component';
import { BrowserModule } from '@angular/platform-browser';
import { FormsModule } from '@angular/forms';
import { FaIconLibrary, FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { fas } from '@fortawesome/free-solid-svg-icons';
import { RouteConfigurationService } from './services/route-configuration.service';
import { P404Component } from './components/p404/p404.component';
import { MetadataService } from './services/metadata.service';

@NgModule({
  declarations: [
    DynamicRoutesComponent,
    ComponentHostDirective,
    NotFoundComponent,
    GenericContentComponent,
    GenericCellComponent,
    SliderComponent,
    LoginComponent,
    P404Component
  ],
  imports: [
    BrowserModule,
    CommonModule,
    FormsModule,
    TranslocoRootLibModule,
    FontAwesomeModule,
  ],
  exports: [
    DynamicRoutesComponent,
    ComponentHostDirective
  ],
  providers: [
    HttpHelperService,
    SecurityService,
    CanActivateNavigation,
    SecurityDataService,
    JsonConfigService,
    DataSourceService,
    WebApiService,
    DynamicComponentsService,
    RouteConfigurationService,
    // MetadataService
  ]
})
export class VgCommonModule {
  static forRoot(environment: any): ModuleWithProviders<VgCommonModule> {
    // console.log(configuration);
    return {
      ngModule: VgCommonModule,
      providers: [
        ConfigurationService, 
        { provide: 'environment', useValue: environment },
      ]
    };
  }
}
