import { Component, EventEmitter, Input, OnDestroy, OnInit, Output, SecurityContext, ViewChild, ViewContainerRef } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { DataSourceService } from '../../services/data-source.service';
import { DynamicComponentsService } from '../dynamic-component/component-lookup';
import { ComponentHostDirective } from '../../directives/component-host/componentHost.directive';

@Component({
  selector: 'lib-generic-cell',
  templateUrl: './generic-cell.component.html',
  styleUrls: ['./generic-cell.component.scss']
})
export class GenericCellComponent implements OnInit, OnDestroy {
  @Input() configuration: any;
  @ViewChild(ComponentHostDirective, { read: ViewContainerRef }) public appComponentHost: ViewContainerRef | undefined;
  @Output() isChanged: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() blockSaved: EventEmitter<any> = new EventEmitter<any>();

  private component: any = null;
  private paramMap: any = null;
  private componentsLoaded = false;
  private subscribedHandles = new Map<string, any>();


  constructor(private activatedRoute: ActivatedRoute, private router: Router, private sanitizer: DomSanitizer, private dynamicComponentsService: DynamicComponentsService, private datasourceService: DataSourceService) { }

  ngOnInit(): void {

    // console.log('GenericCellComponent');

    this.subscribedHandles.set('paramMap', this.activatedRoute.paramMap.subscribe(data => {
      this.paramMap = data;
      if (this.componentsLoaded) this.getData();

      // console.log('GenericCellComponent::this.paramMap', this.paramMap);
    }));
    setTimeout(() => {
      this.loadComponents();
    }, 0);
  }

  public ischanged = (data: any) => {
    // console.log('GenericCellComponent: Is changed');
    this.isChanged.emit(data);
  }

  public isblockSaved = (data: any) => {
    // console.log('GenericCellComponent: Is blockSaved');
    this.blockSaved.emit(data);
  }


  private loadComponents() {
    // console.log('this.configuration', this.configuration);
    if (this.appComponentHost) {
      // console.log('this.appComponentHost');

      // const type = this.dynamicComponentsService.getType('VayvoMenuComponent');
      const type = this.dynamicComponentsService.getType(this.configuration.component);

      if (type) {
        const viewContainerRef = this.appComponentHost;
        viewContainerRef.clear();
        const componentRef = viewContainerRef.createComponent(type);

        this.component = componentRef.instance as any;
        this.component.config = this.configuration;

        // console.log('generic-cell: component: ' + this.configuration.component, this.configuration);

        this.getData();
        if (this.component.ngOnChanges) this.component.ngOnChanges();

        if (this.component.isChanged) {
          this.component.isChanged.subscribe((event: any) => { this.onIsChanged(this.component, event); });
        }

        if (this.component.blockSaved) {
          this.component.blockSaved.subscribe((event: any) => { this.onSave(this.component, event); });
        }

        this.componentsLoaded = true;
      }
    }
  }
  
  public onIsChanged = (bloco: string, event: boolean) => {
    this.isChanged.emit(event);
  }

  private onSave = (block: any, event: any) => {
    this.blockSaved.emit(event);
  }

  private getData = () => {
    if (this.datasourceService.isInitialized()) {
      // this.configuration.datasourceparams

      const params = { ...this.configuration.datasourceparams };
      if (params) {
        Object.keys(params).forEach(key => {
          if (typeof params[key] === 'string' || params[key] instanceof String) {
            if (params[key].startsWith(':')) {
              // console.log('Param: ' + key, params[key]);
              // console.log('this.paramMap', this.paramMap);
              const v = this.paramMap.get(key);
              // console.log('Param: v = ', v);
              params[key] = v;
            }
          }
        });
      }

      // console.log('this.configuration.datasourceparams', params);
      this.subscribedHandles.set('data', this.datasourceService.getData(this.configuration.datasource, params).subscribe(dt => {
        this.component.datasource = dt;
        if (this.component.ngOnChanges) this.component.ngOnChanges();
      }));
    } else {
      setTimeout(this.getData, 300);
    }
  }

  public userClick = (elem: any) => {
    // console.log('userClick', elem);

    if (elem.route !== undefined) {
      // console.log('userClick, route', elem.route);
      this.router.navigate([elem.route]);

    } else if (elem.anchor) {
      // console.log('userClick, anchor', elem.anchor);

    }
  }

  public btLang = (lang: string) => {
    console.log('lang', lang);
  }

  public getHtml = (text: string) => {
    // console.log('sanitizer', this.sanitizer.bypassSecurityTrustHtml(text));

    //return this.sanitizer.bypassSecurityTrustHtml(text);
    return this.sanitizer.sanitize(SecurityContext.HTML, text) || '';
    // return text;
  }

  public ngOnDestroy(): void {
    this.subscribedHandles.forEach(sub => {
      if (sub) { sub.unsubscribe(); }
    });
  }
}
