// import { isPlatformBrowser } from '@angular/common';
import { AfterViewInit, Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ComponentLookup } from 'libs/vg-common';
import { ConfigurationService } from 'src/app/services/configuration.service';
import { VayvoSearchComponent } from '../vayvo-search/vayvo-search.component';

declare var jQuery: any;

@Component({
  selector: 'app-vayvo-menu',
  templateUrl: './vayvo-menu.component.html',
  styleUrls: ['./vayvo-menu.component.scss']
})
@ComponentLookup('VayvoMenuComponent')
export class VayvoMenuComponent implements OnInit, AfterViewInit {
  public config: any = null;
  public language = '';

  // public activeSiteSection = '';

  constructor(private route: ActivatedRoute, private modalService: NgbModal, private configurationService: ConfigurationService) {
  }

  ngOnInit(): void {
    this.language = this.configurationService.getLanguage();
  }

  ngAfterViewInit() {
    if (this.configurationService.isPlatformBrowser) {

      const nav = jQuery('nav#site-navigation-pro ul.sf-menu');
      nav.superfish({
        popUpSelector: 'ul.sub-menu, .sf-mega', 	// within menu context
        delay: 200,                	// one second delay on mouseout
        speed: 0,               		// faster \ speed
        speedOut: 200,             		// speed of the closing animation
        animation: { opacity: 'show' },		// animation out
        animationOut: { opacity: 'hide' },		// adnimation in
        cssArrows: true,              		// set to false
        autoArrows: true,                    // disable generation of arrow mark-up
        disableHI: true,
      });

      /* Sticky Landing Page Header */
      const header = jQuery('header.sticky-header');
      header.scrollToFixed({
        minWidth: 768
      });

      /* Remove Fixed Heading on Mobile */
      jQuery(window).resize(function () {
        const width_progression = jQuery(document).width();
        if (width_progression < 768) {
          jQuery('header.sticky-header').trigger('detach.ScrollToFixed');
        }
      }).resize();

      setTimeout(() => {
        jQuery(window).resize(); // force rezize to occur
      }, 0);

      jQuery('#mobile-bars-icon-pro').click(function (e: any) {
        e.preventDefault();
        jQuery('#mobile-navigation-pro').slideToggle(350);
        // const mn = jQuery('#mobile-menu-pro');
        // if (mn.hasClass('collapsed') === false)
        // mn.addClass('collapsed');
        // // mn.removeClass('collapsed');
         jQuery("header#masthead-pro").toggleClass("active-mobile-icon-pro");
        //  jQuery("header#videohead-pro").toggleClass("active-mobile-icon-pro");
      });


      jQuery('ul#mobile-menu-pro').slimmenu({
        resizeWidth: '90000',
        collapserTitle: 'Menu',
        easingEffect: 'easeInOutQuint',
        animSpeed: 350,
        indentChildren: false,
        childrenIndenter: '- '
      });

      // jQuery("#progression-studios-header-search-icon").on('click', function(e: any){
      //   var $this = jQuery(".header-container");
      //     if ($this.hasClass('active-search-icon-pro')) {
      //         $this.removeClass('active-search-icon-pro').addClass('hide-search-icon-pro');
      //     } else {
      //         $this.addClass('active-search-icon-pro');
      //     }		
      // });

    }
  }

  public btLang = (lang: string) => {

    // console.log('lang', lang);

    this.configurationService.setLanguage(lang);
    this.language = this.configurationService.getLanguage();
    // reload page
    if (this.configurationService.isPlatformBrowser) {

      location.href = location.href;
    }
  }

  public isSectionActive(route: string, anchor: string): boolean {
    let ret = false;

    if (this.configurationService.isPlatformBrowser) {

      if (!anchor) {
        // console.log('anchor empty');
        switch (route) {
          case undefined:
          case '/':
          case '':
            ret = location.hash === '' && (location.pathname === '/' || location.pathname === '');
            break;
          default:
            ret = location.href.indexOf(route) !== -1;
            break;
        }
      } else {
        // console.log('anchor not empty');

        ret = location.href.indexOf(route) !== -1 && (!anchor || location.href.indexOf(anchor) !== -1);
      }

    }
    // return section === this.activeSiteSection;
    // console.log('isSectionActive, route: ' + route + ' / ' + anchor + ' => ret=' + ret);
    return ret;
  }

  public search = () => {
    this.modalService.open(VayvoSearchComponent, { windowClass: 'modal-holder', size: 'xl' }).result.then((result: any) => {
      const a = 1;
      // alert('xxx');
    }, (reason) => {

      /*Leave empty or handle reject*/

    });
  }

  public subMenuOpen = false;


  public subMenuClick = (event: any, submenu: any) =>{
    const sm =jQuery(submenu);
    if (this.subMenuOpen) sm.hide(300); else sm.show(500);
    this.subMenuOpen = !this.subMenuOpen;
    // event.stopPropagation();
  }
}
