import { Injectable, Injector } from '@angular/core';
import { Router, RouterStateSnapshot, ActivatedRouteSnapshot, CanActivate } from '@angular/router';
import { HttpClient, HttpHeaders, HttpInterceptor, HttpRequest, HttpHandler, HttpEvent, HttpErrorResponse } from '@angular/common/http';

import { Observable, interval, throwError } from 'rxjs';
import { map, catchError, takeWhile } from 'rxjs/operators';
import jwtDecode from 'jwt-decode';

import { BehaviorSubject, SubscriptionLike as ISubscription } from 'rxjs';
import { ApplicationError } from '../model/generic/applicationError';
import * as _ from 'lodash-es';
import { SecurityDataService } from './security-data.service';
// import { Configuration } from '../app.configuration';
import { ApiReturn } from '../model/generic/apireturn';
import { ConfigurationService } from './configuration.service';

export class UserInfo {
  // public username: string;
  public name?: string;
  public email?: string;
  public photoId?: number;
  public entityId?: number;
  public effectivePermissions?: [];
}

export class UserExpireInfo {
  public timeout: number;
  public totalTimeout: number;

  constructor(timeout: number, totalTimeout: number) {
    this.timeout = timeout;
    this.totalTimeout = totalTimeout;
  }
}

export enum ENModules {
  Experts_255 = 255,
  Experts_UserAndRegistration_401 = 401,
  Experts_Application_402 = 402,
  Experts_ApplicationValidationOne_403 = 403,
  Experts_ApplicationValidationTwo_404 = 404,
  Experts_ApplicationRefusalEmails_405 = 405,
  Experts_ApplicationComments_406 = 406,
  Experts_ApplicationNextMeeting_407 = 407,
  Experts_Experts_410 = 410,
  Experts_ContractInformation_411 = 411,
  Experts_Meetings_414 = 414,
  Experts_EligibleFiles_416 = 416,
  Experts_Messages_480 = 480,
  Experts_Configuration_490 = 490,
  Experts_EmailTemplates_491 = 491,
  Experts_ContractTemplate_492 = 492,
  Experts_Admin_499 = 499,
}

@Injectable()
export class SecurityService {
  private userInfo = new BehaviorSubject<UserInfo | null>(null);
  public userInfo$ = this.userInfo.asObservable();

  private userExpiring = new BehaviorSubject<UserExpireInfo | null>(null);
  public userExpiring$ = this.userExpiring.asObservable();

  private handlerExpirationTimer: any = null;
  private lastServerAccess: Date = new Date();
  private monitoringExpiration: boolean = false;

  private tokenEndpoint = 'connect/token';
  private contentType = 'application/x-www-form-urlencoded';

  private client_id = 'vGrafWebApi';
  private client_secret = 'vGrafWebApi..2019';
  private scope = 'vGrafWebApi offline_access';
  // public application = window['appConfig'].ApplicationModule; // window[ApplicationModule] 'frontoffice';

  private extraInfoForBackoffice = '';

  // tslint:disable-next-line: max-line-length
  constructor(private http: HttpClient, private router: Router, private securityDataService: SecurityDataService, private configuration: ConfigurationService) {
    // check if is a page reload (sessionStorage contains currentUser)
    if (this.configuration.isPlatformBrowser) {
      const curUser = sessionStorage.getItem('currentUser');
      if (curUser) {
        const data = JSON.parse(curUser);
        if (data) {  // page reload, ajusta timer de expiração
          // console.log('SecurityService:: page reload, ajusta timer de expiração');
          this.setMonitoringExpiration(true);
          this.setExpirationHandler(data);
        }
      }
    }
  }

  public GetUserInfo = (): UserInfo | null => {
    // console.log('GetUserInfo');
    if (this.configuration.isPlatformBrowser) {
      const curUser = sessionStorage.getItem('currentUser');
      // console.log('GetUserInfo, curUser', curUser);
      if (curUser) {
        const data = JSON.parse(curUser);
        if (data) {
          // token to object
          // console.log('GetUserInfo, com data');
          const jwtToken = jwtDecode((data as any).access_token);
          // console.log('GetUserInfo, jwtToken:', jwtToken);

          const uInfo = new UserInfo();
          uInfo.name = (jwtToken as any).client_Name;
          uInfo.email = (jwtToken as any).client_Email;
          uInfo.photoId = (jwtToken as any).client_PhotoId;

          const efPerm = (data as any).effectivePermissions;
          uInfo.effectivePermissions = efPerm;
          // console.log('GetUserInfo, uInfo:', uInfo);

          return uInfo;
        }
      } else {
        // console.log('GetUserInfo, sem data');
        const tok = this.securityDataService.getToken();
        if (tok) {
          // console.log('GetUserInfo, com tok');
          const jwtToken = jwtDecode(tok);
          const uInfo = new UserInfo();
          uInfo.name = (jwtToken as any).client_Name;
          uInfo.email = (jwtToken as any).client_Email;
          uInfo.photoId = (jwtToken as any).client_PhotoId;
          uInfo.effectivePermissions = JSON.parse((jwtToken as any).client_BOUserPermissions);

          // console.log(new Date((jwtToken as any).auth_time));
          // console.log(new Date((jwtToken as any).exp));
          // console.log(new Date((jwtToken as any).iat));

          return uInfo;
        }
      }
    }
    return null;
  }

  public UserInfoSubscribe = (func: any): ISubscription => {
    const sub = this.userInfo$.subscribe(func);
    this.userInfo.next(this.GetUserInfo());
    return sub;
  }

  public refreshUserInfo = () => {
    this.userInfo.next(this.GetUserInfo());
  }

  public UserExpiringSubscribe = (func: any): ISubscription => {
    const sub = this.userExpiring$.subscribe(func);
    return sub;
  }

  public login(username: string, password: string): any {
    this.setExpirationHandler(null);

    const fullUrl = `${this.configuration.appConfig.server}/${this.tokenEndpoint}`;
    const myHeaders = new HttpHeaders()
      .set('Content-Type', this.contentType)
      .set('Language', this.configuration.getLanguage())
      .set('Accept', 'application/json');

    let body = `grant_type=password&application=${this.configuration.appConfig.applicationModule}`;
    body += `&AlwaysSendClientClaims=true&AlwaysIncludeUserClaimsInIdToken=true`;

    // body += `&username=${username}&password=${password}`;
    body += `&username=${username}&password=${encodeURIComponent(password)}`;

    body += `&client_id=${this.client_id}&client_secret=${this.client_secret}&scope=${this.scope}`;

    if (this.extraInfoForBackoffice) {
      body += '&' + this.extraInfoForBackoffice;
    }

    // console.log('SecurityService::login', fullUrl);
    // console.log('SecurityService::login', body);

    return this.http
      .post(fullUrl, body, { headers: myHeaders })
      .pipe(
        map(response => {
          const data = response;
          if (data && (data as any).access_token) {
            // console.log('login::data:', data);
            if (this.configuration.appConfig.debugMode) { console.log((data as any).access_token); }

            this.configuration.setOAuthToken((data as any).access_token);

            // calculate expire date
            const expireDate = new Date();
            expireDate.setSeconds(expireDate.getSeconds() + (data as any).expires_in);
            (data as any).expireDate = expireDate.getTime();
            console.log('LOGIN: next Refresh date: ' + new Date((<any>data).expireDate));
            // set timeout to expiration date of token
            this.lastServerAccess = new Date();
            this.setMonitoringExpiration(true);
            this.setExpirationHandler(data);

            // console.log('login::check is Backoffice:');

            if (this.configuration.appConfig.applicationModule === 'Backoffice') {
              // console.log('login::Backoffice:');
              return this.securityDataService.getUserEffectivePermissions().pipe(map(dataEF => {
                // console.log('login::dataEF:', dataEF);
                (data as any).effectivePermissions = dataEF;
                sessionStorage.setItem('currentUser', JSON.stringify(data));
                this.userInfo.next(this.GetUserInfo());
                return true;
              }));

            } else {
              sessionStorage.setItem('currentUser', JSON.stringify(data));
              this.userInfo.next(this.GetUserInfo());
            }

            return true;
          } else {
            this.userInfo.next(this.GetUserInfo());
            return false;
          }
        }), catchError(this.handleError));
  }


  // loginAsBo(bouserId: number, bousername: string, bouseremail: string) {
  //   this.application = 'backoffice';

  //   this.extraInfoForBackoffice = `&user-name=${bousername}&user-id=${bouserId}&user-email=${bouseremail}`;


  //   const usernameExperts = 'ExpertsBackOffice';
  //   const passwordExperts = '_ExpertsBO#2021!'; // => "nsP +5UvATcu7B1wzDJ29+jMSLDlNUcUJnQ2ilimTlGh8jm8TzFTtZQ=="

  //   return this.login(usernameExperts, passwordExperts);
  // }



  private setExpirationHandler = (data: any) => {
    if (data) {
      const aux =
        interval(5000).pipe(
          takeWhile(() => {
            const timer = data.expireDate - (new Date().getTime());
            return this.monitoringExpiration && (this.handlerExpirationTimer != null && timer >= 0);
          }));

      this.handlerExpirationTimer = aux.subscribe(x => {
        // calculte expire interval in miliseconds
        const tokenExpiresIn = data.expireDate - (new Date().getTime());

        // calcula sliding expiration date
        const slidingExpirationTimeout =
          (this.lastServerAccess.getTime() + this.configuration.authenticationSlidingExpiration * 1000 - (new Date()).getTime()) / 1000;

        // console.log('SS: monitoringExpiration:' + this.monitoringExpiration +
        //   ', Token expires in: ' + tokenExpiresIn / 1000 +
        //   ', LastServerAccess:' + this.lastServerAccess +
        //   ', SlidingExpirationDate: ' + slidingExpirationTimeout);

        // validate if is time to automatic renovation (sliding expiration)
        if (tokenExpiresIn / 1000 < 30 && slidingExpirationTimeout > 30) {
          this.refresh(false)?.subscribe((sucess: any) => {
            if (sucess) {
            }
          },
            (error: any) => {
              console.log(error);
            });
        } else {
          this.userExpiring.next(
            new UserExpireInfo(slidingExpirationTimeout * 1000, this.configuration.authenticationSlidingExpiration * 1000)
          );
        }
      });
    } else {
      if (this.handlerExpirationTimer != null) {
        this.handlerExpirationTimer.unsubscribe();
        this.handlerExpirationTimer = null;
      }
    }
  }

  public setMonitoringExpiration = (action: boolean) => {
    this.monitoringExpiration = action;
  }

  public refresh = (manual: boolean = false): Observable<boolean | null> | null => {
    console.log('SecurityService: refresh token ############################################## ', new Date());
    this.setExpirationHandler(null);

    const data = JSON.parse(sessionStorage.getItem('currentUser') || '');

    if (data) {
      const fullUrl = `${this.configuration.appConfig.server}/${this.tokenEndpoint}`;
      const myHeaders = new HttpHeaders()
        .set('Authorization', 'Bearer ' + data.access_token)
        .set('Content-Type', this.contentType)
        .set('Accept', 'application/json');

      // console.log('GO => data.access_token', data.access_token);

      let body = `grant_type=refresh_token&application=${this.configuration.appConfig.applicationModule}`;
      body += `&refresh_token=${data.refresh_token}&access_token=${data.access_token}`;
      body += `&client_id=${this.client_id}&client_secret=${this.client_secret}&scope=${this.scope}`;

      return this.http
        .post(fullUrl, body, { headers: myHeaders })
        .pipe(
          map(response => {
            const authData = response;
            if (authData && (authData as any).access_token) {
              // console.log('FROM <= data.access_token', (<any>authData).access_token);
              // console.log('-------------------------');
              // calculate expire date
              const expireDate = new Date();
              expireDate.setSeconds(expireDate.getSeconds() + (authData as any).expires_in);
              (authData as any).expireDate = expireDate.getTime();
              (authData as any).effectivePermissions = data.effectivePermissions;

              // console.log('next Refresh date: ' + new Date((<any>authData).expireDate));

              this.configuration.setOAuthToken((authData as any).access_token);

              sessionStorage.setItem('currentUser', JSON.stringify(authData));

              // set timeout to expiration date of token
              if (manual) { this.lastServerAccess = new Date(); }
              this.setMonitoringExpiration(true);
              this.setExpirationHandler(authData);

              this.userInfo.next(this.GetUserInfo());

              return true;
            } else {
              // falhou no refresh token => show login popup
              this.userExpiring.next(
                new UserExpireInfo(1, 1)
              );
              return false;
            }
          }), catchError(err => {
            this.handleError(err);
            this.userExpiring.next(
              new UserExpireInfo(1, 1)
            );
            return new Observable<boolean | null>();
          })
        );
    }
    return null;
  }

  public logout(gotoLogin: boolean, gotoHome: boolean) {
    // remove user from local storage to log user out
    sessionStorage.removeItem('currentUser');
    // const data = sessionStorage.getItem('currentUser');

    this.extraInfoForBackoffice = '';
    this.userInfo.next(null);
    this.userExpiring.next(null);
    this.configuration.setOAuthToken('');

    this.setExpirationHandler(null);
    this.setMonitoringExpiration(false);

    if (gotoLogin) { this.router.navigate(['/login'], { queryParams: { returnUrl: '/' } }); }
    if (gotoHome) { this.router.navigate(['/']); }
  }

  public resetPassword = (resetId: string, password: string): Observable<boolean> => {
    const toSend = JSON.stringify({ resetId, newPassword: password });
    const fullUrl = `${this.configuration.appConfig.server}/api/v1/SecurityExtension/ResetPassword`;
    const language = this.configuration.getLanguage();
    const headersOpt = new HttpHeaders()
      .set('Content-Type', 'application/json')
      .set('Accept', 'application/json')
      .set('Language', language);
    const myHeaders = { headers: headersOpt };

    return this.http
      .post(fullUrl, toSend, myHeaders)
      .pipe(
        map(response => {
          const ret: ApiReturn = response as any;
          if (!ret.errorCode && !ret.errorMessage) {
            return ret.value;
          } else {  // aplication error
            throw new ApplicationError(ret.errorCode || undefined, ret.errorMessage || undefined);
          }
        })
        , catchError(this.handleError));
  }

  public getByGuid = (guid: string): Observable<boolean> => {
    const language = this.configuration.getLanguage();
    const headersOpt = new HttpHeaders()
      .set('Content-Type', 'application/json')
      .set('Accept', 'application/json')
      .set('Language', language);
    const myHeaders = { headers: headersOpt };

    const fullUrl = `${this.configuration.appConfig.server}/api/security/GetByGuid/${guid}`;
    return this.http
      .get(fullUrl, myHeaders)
      .pipe(
        map(response => {
          const ret: ApiReturn = response as any;
          if (!ret.errorCode && !ret.errorMessage) {
            return ret.value;
          } else {  // aplication error
            throw new ApplicationError(ret.errorCode || undefined, ret.errorMessage || undefined);
          }
        })
        , catchError(this.handleError)
      );
  }

  public changePassword = (oldPassword: string, newPassword: string): Observable<boolean> => {
    const toSend = JSON.stringify({ currentPassword: oldPassword, newPassword });
    const fullUrl = `${this.configuration.appConfig.server}/api/security/ChangePassword`;
    const language = this.configuration.getLanguage();
    const headersOpt = new HttpHeaders()
      .set('Content-Type', 'application/json')
      .set('Accept', 'application/json')
      .set('Authorization', 'Bearer ' + this.configuration.getOAuthToken())
      .set('Language', language);
    const myHeaders = { headers: headersOpt };

    return this.http
      .post(fullUrl, toSend, myHeaders)
      .pipe(
        map(response => {
          const ret: ApiReturn = response as any;
          if (!ret.errorCode && !ret.errorMessage) {
            return ret.value;
          } else {  // aplication error
            throw new ApplicationError(ret.errorCode || undefined, ret.errorMessage || undefined);
          }
        })
        , catchError(this.handleError));
  }


  public alive = (request: HttpRequest<any>) => {

    const fullUrl = `${this.configuration.appConfig.server}/${this.tokenEndpoint}`;
    if (request.url !== fullUrl) {    // exclude calls to the token (refresh)
      // console.log('SecuritySevice ALIVE !!!!!!!!!!!!!!!!!!!!!!!! ALIVE AND KICKING');
      this.lastServerAccess = new Date();
    }
  }

  // http://jasonwatmore.com/post/2016/09/29/angular-2-user-registration-and-login-example-tutorial
  // http://jasonwatmore.com/post/2016/12/08/angular-2-redirect-to-previous-url-after-login-with-auth-guard

  public canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
    if (route && route.data) {
      // console.log('SecutityService::canActivate');
      // console.log('SecutityService::route.data', route.data);
      const uInfo = this.GetUserInfo();
      if (!uInfo) {
        // if (this.configuration.appConfig.applicationModule === 'Backoffice' && route.data.moduleId) {
        //   this.router.navigate(['/bo'], { queryParams: { returnUrl: state.url } });
        //   return false;
        // } else {
        this.router.navigate(['/login'], { queryParams: { returnUrl: state.url } });
        return false;
        // }
      }

      // TODO: há user, validar que tem permissões para esta opção
      // permissão da rota .... route.data.navigationName, deve mapear com
      if (this.configuration.appConfig.applicationModule === 'backoffice' && route.data['moduleId']) {
        const permissions = uInfo.effectivePermissions;
        console.log('SecutityService::permissions', permissions);
        // const myPermission = _.find(permissions, (o) => {
        //   return o.IDLock === route.data.moduleId && o.IDUserPermissionLockType === 'M';
        // });
        // if (myPermission && myPermission.IDUserPermissionAccessType !== 'D') {
        //   return true;
        // }
        // return false;
      }

    }
    return true;
  }

  protected handleError(err: any) {
    let errMsg = '';

    if (err instanceof ApplicationError) {
      return throwError(err);
    }

    try {
      const errorObject = JSON.parse(err._body);
      if (errorObject) {
        if (errorObject.error) {
          errMsg = errorObject.error + ' - ' + errorObject.error_description;
        } else if (errorObject.exceptionMessage) {
          errMsg = errorObject.exceptionMessage;
        }
      }
      return throwError(errMsg);
    } catch (error) {
    }

    if (err.error instanceof Error) {
      errMsg = 'An error occurred:' + err.error.message;
    } else {
      errMsg = `Backend returned code ${err.status}, body was: ${err.error}`;
    }

    console.error(errMsg);
    return throwError(errMsg);
  }
}

@Injectable()
export class CanActivateNavigation implements CanActivate {
  constructor(private securityService: SecurityService) { }

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean> | Promise<boolean> | boolean {
    return this.securityService.canActivate(route, state);
  }
}

@Injectable()
export class RestInterceptor implements HttpInterceptor {
  constructor(private injector: Injector) {
  }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    // add authorization header with jwt token if available => possible todo
    // console.log('RestInterceptor');
    const securityService = this.injector.get(SecurityService); // to avoid circular dependencys

    securityService.alive(request); // excelude the call to the refresh token

    return next.handle(request)
      .pipe(
        catchError((err: HttpErrorResponse) => {

          if (err.error instanceof Error) {
            // A client-side or network error occurred. Handle it accordingly.
            console.error('An error occurred:', err.error.message);
          } else {
            // The backend returned an unsuccessful response code.
            // The response body may contain clues as to what went wrong,

            // erro de autenticação
            if (err.error.error === 'Falha de Autenticação') {
              const errApp = new ApplicationError(err.error.error_description);
              errApp.systemError = false;
              return throwError(errApp as ApplicationError);
            }

            if (err.error.exceptionType) {
              if (err.error.exceptionType === 'System.ApplicationException') {
                // application error message
                const errApp = new ApplicationError(err.error.exceptionMessage);
                errApp.systemError = false;
                return throwError(errApp as ApplicationError);
              }
              if (err.error.exceptionType.lastIndexOf('System.', 0) === 0) {
                // server side exceptions
                const errApp = new ApplicationError(err.error.exceptionMessage);
                errApp.systemError = true;
                return throwError(errApp as ApplicationError);
              }
            } else {
              // server side exceptions
              const errApp = new ApplicationError(err.message);
              errApp.systemError = true;
              return throwError(errApp as ApplicationError);
            }

            console.error(`Backend x returned code ${err.status}, body was: ${err.error}`);
          }

          switch (err.status) {
            case 400:
              break;
            case 401:
              break;
            default:
              break;
          }

          //     // ...optionally return a default fallback value so app can continue (pick one)
          //     // which could be a default value (which has to be a HttpResponse here)
          //     // return Observable.of(new HttpResponse({body: [{name: "Default value..."}]}));
          //     // or simply an empty observable
          //     return Observable.empty<HttpEvent<any>>();

          // const appError = new Error('');
          // appError.

          return throwError(err);
        })
      );

  }

}
