<div id="video-page-title-pro" class="video-embed-height-adjust pointer" *ngIf="datasource" title="{{datasource?.backgroundImageId}}"
    [ngStyle]="{ 'background-image': (getImage(datasource, 0) ? 'url(' + getImage(datasource, 0) + ')' : null)}"
    [routerLink]="['/', config.route, datasource?.tituloUrlEncoded, 1, 1]">

    <a class="video-page-title-play-button afterglow" *ngIf="!serie"><i class="fas fa-play"></i></a>
    <div id="video-embedded-container">
        <div style="padding:26.25% 0 0 0;position:relative;"></div>
    </div>
    <div id="video-page-title-gradient-base"></div>
</div>


<div id="content-pro">
    <div class="container custom-gutters-pro" *ngIf="datasource">
        <div id="video-post-container">
            <h1 class="video-post-heading-title">{{datasource.titulo}}</h1>
            <div class="clearfix"></div>

            <ul id="video-post-meta-list">
                <li><a href="#" onclick="return false;">{{(serie ? 'conteudo.Serie' : 'conteudo.Espectaculo') |
                        transloco}}</a></li>
                <li *ngIf="datasource.genero"><a href="#" onclick="return false;">{{datasource.genero}}</a></li>
                <li *ngIf="datasource.ano" id="video-post-meta-year">{{datasource.ano}}</li>
                <li *ngIf="datasource.classificacao" id="video-post-meta-rating">
                    <span>{{datasource.classificacao}}</span>
                </li>
            </ul>
            <div class="clearfix"></div>

            <div id="vayvo-video-post-content" *ngIf="datasource.sinopse">
                <p style="text-align: justify;" [innerHTML]="datasource.sinopse"></p>
            </div>

            <!-- // promotor -->
            <div id="video-cast-display-post-section" *ngIf="datasource.promotor">
                <ul class="vayvo-video-cast-list">
                    <li *ngFor="let elem of datasource.promotor"><a href="" class="pointer"
                            [routerLink]="['/entidade', elem.nomeEncoded]">
                            <div class="vayvo-video-cast-photo"
                                [ngStyle]="{ 'background-image': (getImage(elem, 1) ? 'url(' + getImage(elem, 1) + ')' : null)}">
                            </div>
                            <h6>{{elem.nome}}</h6>
                            <!-- <div class="clearfix-pro"></div> -->
                        </a></li>
                </ul>
                <!-- <div class="clearfix-pro"></div> -->
            </div>

            <!-- // elenco -->
            <div id="video-cast-display-post-section" *ngIf="datasource.elenco">
                <h3>{{"conteudo.Elenco" | transloco }}</h3>
                <ul class="vayvo-video-cast-list">
                    <li *ngFor="let elem of datasource.elenco"><a href="" class="pointer"
                            [routerLink]="['/entidade', elem.nomeEncoded]">
                            <div class="vayvo-video-cast-photo"
                                [ngStyle]="{ 'background-image': (getImage(elem, 1) ? 'url(' + getImage(elem, 1) + ')' : null)}">
                            </div>
                            <h6 title="{{elem.avatarFileManagerId}}">{{elem.nome}}</h6>
                            <!-- <div class="clearfix-pro"></div> -->
                        </a></li>
                </ul>
                <!-- <div class="clearfix-pro"></div> -->
            </div>


            <div class="vayvo-progression-video-season-container">
                <ul class="vayvo-progression-video-season-navigation">
                    <li class="current progression-video-season-title" *ngFor="let temp of temporadas"
                        [ngClass]="{current: curTemporada === temp}">
                        <a style="cursor: pointer" (click)="curTemporada = temp">{{temp.titulo}}</a>
                    </li>
                </ul>
                <!-- <div class=".clearfix"></div> -->

                <ul class="progression-studios-episode-list-main" *ngIf="curTemporada">
                    <li class="progression-studios-episode-list-item" *ngFor="let episode of curTemporada?.episode">
                        <div class="progression-episode-list-flex">
                            <div class="progression-studios-episode-image-container">
                                <div class="progression-episode-list-left-margin">
                                    <a class="afterglow"
                                        [routerLink]="['/', config.route, datasource.tituloUrlEncoded, episode.temporada, episode.episodio]">
                                        <div class="progression-episode-list-image-container">
                                            <img src="{{getImage(episode, 1) || 'assets/images/Gray1700x1133.png' }}"
                                                alt="{{episode.titulo}}">
                                            <div class="progression-episode-list-overlay-play">
                                                <i class="fas fa-play"></i>
                                            </div>
                                        </div>
                                    </a>
                                </div>
                            </div>

                            <div class="progression-studios-episode-right-container">
                                <div class="progression-episode-list-right-margin">
                                    <a href="" class="afterglow"
                                        [routerLink]="['/', config.route, datasource.tituloUrlEncoded, episode.temporada, episode.episodio]">
                                        <h2 class="progression-episode-list-title">{{episode.titulo}}</h2>
                                    </a>
                                    <ul class="progression-studios-episode-list-meta">
                                        <li class="progression-episode-list-meta-release-date"
                                            *ngIf="episode.dataLancamento">{{episode.dataLancamento | date : 'dd MMM
                                            yyyy'}}</li>
                                        <li class="progression-episode-list-meta-release-date" *ngIf="episode.duracao">
                                            {{episode.duracao }} min.</li>
                                    </ul>

                                    <div class="progression-episode-list-short-description">{{episode.sinopse}}</div>
                                </div>
                            </div>

                        </div>
                    </li>
                </ul>
            </div>

        </div>

        <div id="video-post-sidebar" xstyle="background-color: yellow;">
            <div class="content-sidebar-section video-sidebar-section-release-date m-0 p-0 mb-4"
                style="border: 1px solid #555;background-repeat: no-repeat;background-position:center center;background-size: cover;min-height: 150px;position: relative;"
                *ngIf="getImage(datasource, 1)"
                [ngStyle]="{ 'background-image': 'url(' + getImage(datasource, 1) + ')'}">

                <ng-container *ngIf="serie">
                    <a class="video-page-title-play-button afterglow pointer"
                        [routerLink]="['/', config.route, datasource.tituloUrlEncoded, 1, 1]" style="top: 55px;"><i
                            class="fas fa-play"
                            style="font-size: 22px;border-width: 2px;width: 70px;height: 70px;line-height: 70px;"></i></a>
                    <h4 class="content-sidebar-sub-header m-1">T1/E1</h4>
                </ng-container>
            </div>
            <div class="content-sidebar-section video-sidebar-section-release-date" *ngIf="datasource.dataLancamento">
                <h4 class="content-sidebar-sub-header">{{"conteudo.Release Date" | transloco }}</h4>
                <div class="content-sidebar-short-description">{{datasource.dataLancamento | date : 'dd MMM yyyy'}}
                </div>
            </div>
            <div class="content-sidebar-section video-sidebar-section-length" *ngIf="datasource.duracao">
                <h4 class="content-sidebar-sub-header">{{"conteudo.Duration" | transloco }}</h4>
                <div class="content-sidebar-short-description">{{datasource.duracao}} min.</div>
            </div>

            <div class="content-sidebar-section video-sidebar-section-length"
                *ngIf="datasource.realizador && datasource.realizador.length > 0">
                <h4 class="content-sidebar-sub-header">{{"conteudo.Realizador" | transloco }}</h4>
                <div class="content-sidebar-short-description">
                    <ng-container *ngFor="let ent of datasource.realizador; let idx=index">
                        <ng-container *ngIf="idx !== 0">, </ng-container><a class="lnk"
                            [routerLink]="['/entidade', ent.nomeEncoded]">{{ent.nome}}</a>
                    </ng-container>
                </div>
            </div>

            <div class="clearfix"></div>
        </div>

        <div class="clearfix"></div>
    </div>
</div>

<div class="container xcustom-gutters-pro">
    <h2 class="post-list-heading" *ngIf="datasource?.relacionados?.lista">{{"conteudo.Related" | transloco}}</h2>
    <div class="container">

        <div #flexsliderreldiv class="flexslider carousel">
            <ul class="slides slidesCarousel">
                <li class="liSlidesCarousel" *ngFor="let iten of datasource?.relacionados?.lista; let idx = index">
                    <a class="lnk" href="" [routerLink]="['/', config.routeconteudo, iten.route, 1, 1]">
                        <div [ngStyle]="{ 'background-image': 'url(' + getImage(iten, 0) + ')'}"
                            style="background-repeat: no-repeat;background-size: cover;">
                            <div>
                                <span class="fs22">{{iten.title}}</span><br>
                                <span class="fs15">
                                    <ng-container *ngFor="let at of iten.attributes">{{at}} </ng-container>
                                </span>
                            </div>
                        </div>
                    </a>
                </li>
            </ul> 
        </div>
    </div>
    <!-- <div class="clearfix"></div> -->
</div>


<!-- {{ datasource?.relacionados | json}} -->

<!-- 
config: {{config | json}} <br/><br/>
-->
<!-- datasource: {{ datasource?.relacionados | json}} -->

