import * as _ from 'lodash-es';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Route, Router } from '@angular/router';

@Component({
  selector: 'app-p404',
  templateUrl: './p404.component.html',
  styleUrls: ['./p404.component.scss']
})
export class P404Component implements OnInit, OnDestroy {
  private subData: any;
  public notFound = false;

  constructor(private router: Router, private activatedRoute: ActivatedRoute) {
    console.log('P404Component');
    console.log('P404Component', this.router.url);

    const routeConfig = this.activatedRoute.snapshot.routeConfig;

  }

  ngOnInit(): void {
    this.subData = this.activatedRoute.data.subscribe(datas => {
      const rotas = this.router.config;
      // if (datas.loadSubMenus && rotas.length === 1) {

        // this.workSGDataService.GetTabs().subscribe((groups: WorkSgManagementGroup[]) => {
        //   const childrenRoute: Route[] = [];

        //   groups.forEach(mnu => {
        //     if (mnu.workSgManagement.length > 0) {
        //       const subChildrenRoute: Route[] = [];

        //       mnu.workSgManagement.forEach(smnu => {
        //         subChildrenRoute.push({ path: '', redirectTo: smnu.description.vgNormalize(), pathMatch: 'full' });
        //         subChildrenRoute.push({ path: smnu.description.vgNormalize(), component: WorkSGManagementComponent });
        //       });
        //       childrenRoute.push(
        //         {
        //           path: mnu.description.vgNormalize(), component: WorkSGManagementGroupComponent,
        //           data: { showContent: false }, children: subChildrenRoute
        //         }
        //       );
        //     }

        //   });

        //   if (childrenRoute.length > 0) {

        //     childrenRoute.push({path: '', redirectTo: childrenRoute[0].path, pathMatch: 'full'});

        //     const topRoute: Route = { path: ''};
        //     topRoute.children = childrenRoute;

        //     const p404Route = _.find(rotas, ['path', '**']);

        //     this.router.config = [topRoute, p404Route];

        //     console.log('this.router.config:', this.router.config);

        //     this.router.resetConfig(this.router.config);
        //     this.router.onSameUrlNavigation = 'reload';
        //     this.router.navigateByUrl(this.router.url);
        //   }
        // });

      // }
      // else {
      //   this.notFound = true;
      // }
    });

  }

  ngOnDestroy() {
  }

}
