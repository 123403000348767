import { Injectable } from "@angular/core";

import { catchError, EMPTY, Observable, of, shareReplay, Subject } from 'rxjs';

import { ConfigurationService } from "./configuration.service";
import { HttpHelperService } from "./http-helper.service";

@Injectable()
export class JsonConfigService {

    private controllerName: string = 'v1/JsonConfig';
    private serverConfiguration = new Subject<any>();
    public serverConfiguration$ = this.serverConfiguration.asObservable();
    private cacheConfiguration: Map<string, any> = new Map<string, any>();

    constructor(private httpHelper: HttpHelperService, private configuration: ConfigurationService) {
      this.configuration.configurationSubscribe((appConfig: any)=> {
        if(appConfig?.jsonConfigControllerName) {
          this.controllerName = appConfig.jsonConfigControllerName;
        }
      });
    }

    public getApplicationConfig = (applicationModule: string): Observable<any> => {
        const data = JSON.stringify({ 'ApplicationModule': applicationModule });
        return this.httpHelper.httpPost(this.controllerName, 'configuration', data);
    }

    public GetConfiguration = (name: string, blockName: string, keys: any, language: string) => {
        const toPost = JSON.stringify({ name, blockName, keys });
    
        const cacheName = toPost;
        if (this.cacheConfiguration.has(cacheName) === false) {
          const toPost = JSON.stringify({ name, blockName, keys });
    
          this.cacheConfiguration.set(cacheName, this.httpHelper.httpPost(this.controllerName, 'Configuration', toPost).pipe(
            shareReplay(1),
            catchError(err => {
              this.cacheConfiguration.delete(cacheName);
              return EMPTY;
            })));
        }
        return this.cacheConfiguration.get(cacheName);
      }

}

// {
//     "appConfig": {
//       "server": "http://localhost:5004",
//       "applicationModule": "Frontoffice",
//       "debugMode": true,
//       "languageDefault": "en"
//     }
//   }