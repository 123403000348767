import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";

import { TranslocoService } from "@ngneat/transloco";
import * as _ from 'lodash-es';

import { ConfigurationService as LibConfigurationService } from "libs/vg-common";
import { environment } from "src/environments/environment";

@Injectable()
export class ConfigurationService {
    public appConfig: any = null;
    public isPlatformBrowser = false;

    constructor(private http: HttpClient, private translocoService: TranslocoService, private libConfigurationService: LibConfigurationService) {
        // const baseUrl = environment.baseUrl;
        this.isPlatformBrowser = this.libConfigurationService.isPlatformBrowser;
        console.log('configurationService CTOR');

        let baseUrl : string = '';
        if (this.isPlatformBrowser) {
            baseUrl = environment.baseUrl;
        } else {
            //baseUrl = 'https://arenes.vgraf.pt';
            //baseUrl = 'https://arenes.pt';
             baseUrl = environment.baseUrl;
             baseUrl = 'http://localhost:4240';
        }
        console.log('baseUrl: ', baseUrl);

        this.http.get(`${baseUrl}/appconfig.json`).subscribe((file: any) => {
            console.log('appconfig.json ... done', file);

            this.appConfig = (<any>file).appConfig;
            const lang = this.libConfigurationService.getLanguage() || this.appConfig.languageDefault;
            translocoService.setActiveLang(lang);
            this.libConfigurationService.setLanguage(lang);
            // console.log('this.libConfigurationService.setLanguage(lang)');

            // this.translocoLocalService.setLocale(lang);
            // private service: TranslocoLocaleService
            // set configuration on library's
            this.libConfigurationService.setConfig(this.appConfig);
            // console.log('this.libConfigurationService.setConfig(this.appConfig)');

        });
    }

    public setLanguage = (lg: string) => {
        this.libConfigurationService.setLanguage(lg);
        this.translocoService.setActiveLang(this.appConfig.languageDefault);
    }

    public getLanguage = () => {
        return this.libConfigurationService.getLanguage();
    }
}