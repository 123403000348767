import { AfterViewChecked, AfterViewInit, Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output } from '@angular/core';
import { ComponentLookup, ConfigurationService, WebApiService } from 'libs/vg-common';
import * as _ from 'lodash-es';
import { saveAs } from 'file-saver-es';
import { ActivatedRoute } from '@angular/router';
declare var jQuery: any;

@Component({
  selector: 'app-vayvo-slider',
  templateUrl: './vayvo-slider.component.html',
  styleUrls: ['./vayvo-slider.component.scss']
})
@ComponentLookup('VayvoSliderComponent')
export class VayvoSliderComponent implements OnInit, OnChanges, OnDestroy, AfterViewChecked {
  @Input() public config: any = null;
  @Input() public datasource: any = null;
  @Input() public firstElement: number = 0;
  @Output() elementChanged: EventEmitter<any> = new EventEmitter<any>();

  private jsBinded = false;
  private fileDownloaded = false;

  private sliderIsPlaying = true;

  constructor(private activatedRoute: ActivatedRoute, private configurationService: ConfigurationService, private webApiService: WebApiService) {
  }

  ngOnInit(): void {
    // this.activatedRoute.params.subscribe((params: any) => {
    //   const id = +params['id'];
    //   if (id > 0) {
    //     this.sliderAutoPlay = false;
    //   }
    //   if (this.jsBinded) {

    //     const nav = jQuery('.progression-studios-slider');
    //     nav.flexslider((!this.sliderAutoPlay ? 'pause' : 'play'));
    //   }
    //   console.log('id', id, this.sliderAutoPlay);
    // });
    if (this.configurationService.isPlatformBrowser) {
      window.addEventListener('scroll', this.scrollEvent, true);
    }
  }

  // ngAfterViewInit() {
  //   if (this.configurationService.isPlatformBrowser) {
  //     jQuery(window).scroll = ((event: any) => {
  //       console.log('SCROLLL-SCROLLL-SCROLLL-SCROLLL');
  //       if (this.jsBinded) {
  //         var yPos = jQuery(window).scrollTop();
  //         console.log('SCROLLL', event, yPos);
  //         const nav = jQuery('.progression-studios-slider');
  //         nav.flexslider((yPos > 0  ? 'pause' : 'play'));
  //       }
  //     });
  //   }
  // }

  ngOnDestroy() {
    if (this.configurationService.isPlatformBrowser) {
      window.removeEventListener('scroll', this.scrollEvent, true);
    }
  }

  private scrollEvent = (event: any): void => {
    const scrollTopVal = event.target.scrollingElement.scrollTop;
    // console.log(scrollTopVal);

    if (this.jsBinded) {
      var yPos = jQuery(window).scrollTop();
      // console.log('SCROLLL', event, yPos);
      const nav = jQuery('.progression-studios-slider');
      if (yPos > 0) {
        if (this.sliderIsPlaying) {
          this.sliderIsPlaying = false;
          nav.flexslider('pause');
          // console.log('comando: ', (yPos > 0  ? 'pause' : 'play'));
        }
      } else {
        if (this.sliderIsPlaying === false) {
          // console.log('comando: ', (yPos > 0  ? 'pause' : 'play'));
          this.sliderIsPlaying = true;
          nav.flexslider('play');
        }
      }
    }
  } 

  ngOnChanges(): void {
    if (this.datasource?.lista) {
      // get images
      this.datasource.lista.forEach((element: any) => {
        if (element.backgroundImageId && element.backgroundImage == null) {
          // get the file from the server / or cache
          if (this.configurationService.isPlatformBrowser) {
            this.webApiService.getImageFile(element.backgroundImageId, 1533, 1700).subscribe((data: any) => {
              element.backgroundImage = data.contentArrayBufferBase64;
            });
          }
        }
      });



      // if (this.firstElement !== 0) { // reorder
      //   const id = this.activatedRoute.snapshot.paramMap.get('id');
      //   console.log('id', id);
      //   const aar1 = _.slice(this.datasource.lista, this.firstElement);
      //   const aar2 = _.slice(this.datasource.lista, 0, this.firstElement);
      //   aar1.push(...aar2);
      //   this.datasource.lista = aar1;
      // }
    }
  }

  ngAfterViewChecked() {
    if (this.configurationService.isPlatformBrowser) {
      if (this.datasource && this.jsBinded === false) {
        // console.log('ngAfterViewChecked');
        this.bindJQuery();
        this.jsBinded = true;
      }
    }
  }

  private bindJQuery() {
    const nav = jQuery('.progression-studios-slider');
    nav.flexslider({
      slideshow: true,  		/* Autoplay True/False */
      slideshowSpeed: 8000,	/* Autoplay Speed */
      animation: "fade",		/* Slideshow Transition Animation */
      animationSpeed: 800, 	/* Slide Transition Speed */
      directionNav: false,		/* Left/Right Navigation True/False */
      controlNav: true, //this.config.sliderVertical ? true : false,		/* Bullet Navigaion True/False */
      prevText: "",
      nextText: "",
      // controlsContainer: (this.config.sliderVertical ? jQuery('.custom-controls-container') : jQuery('.custom-navigation-arrow .custom-controls-container-arrow')),
      // customDirectionNav: (this.config.sliderVertical ? jQuery('.custom-navigation a') : jQuery('.custom-navigation-arrow a')),
      after: (e: any) => {
        console.log('slide --- changed', e.currentSlide);
        this.elementChanged.emit(this.datasource.lista[e.currentSlide]);
      }
    });

  }

  public getTruncate = (texto: string, length: number) => {
    const t = texto.replace(/(<([^>]+)>)/ig, '')
    return t.substring(0, Math.min(length, t.length)) + ' ...';
  }

  public getImage = (element: any) => {
    if (element && element.backgroundImageId) {
      if (element.backgroundImage) {
        return ('data:image/jpeg;base64,' + element.backgroundImage);
      }
      else
        return '';
    } else
      return 'assets/images/Gray1700x1133.png';
  }

  public downloadFile(documentId: number): void {
    // uploaded, get from server
    if (this.fileDownloaded === false) {
      this.fileDownloaded = true;
      this.webApiService
        .getFile(documentId)
        .subscribe((data: any) => {
          saveAs(data.content, data.filename);
        },
          (error: any) => console.log(error),
          () => {
            console.log('Download:: Get Document complete');
          });
    }
  }

  public scrollBy = (x: number, y: number) => {
    if (this.configurationService.isPlatformBrowser) {
      window.scrollBy(x, y)
    }
  }
}
