import { isPlatformBrowser } from '@angular/common';
import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import { BehaviorSubject, Observable, SubscriptionLike as ISubscription, SubscriptionLike } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ConfigurationService {

  public appConfig: any = null;
  private appConfigInfo = new BehaviorSubject<any>(null);
  public appConfigInfo$ = this.appConfigInfo.asObservable();

  private languageBS = new BehaviorSubject<string>('');
  private language$ = this.languageBS.asObservable();

  private oAuthToken?: string;
  private oAuthTokenBS = new BehaviorSubject<string | null>(null);
  private oAuthToken$ = this.oAuthTokenBS.asObservable();

  private language: string = 'pt';

  // Autenticação e expiração de autenticação, etc ...
  public expiresLoginPopup = 60;  // segundos para expiração do login => para abrir o popup de aviso (0 não abrir)
  public authenticationSlidingExpiration = 20 * 60;  // segundos para expiração da autenticação por inactividade com o AppServer


  private _isPlatformBrowser = false;
  public get isPlatformBrowser(): boolean {
    return this._isPlatformBrowser;
  }

  constructor(@Inject('environment') public environment: any, @Inject(PLATFORM_ID) private platformId: Object) {
    // this.setConfig(config);
    console.log('LibConfigurationService');


    // global.param1 = true;

    this._isPlatformBrowser = isPlatformBrowser(this.platformId);
  }

  public setConfig = (config: any) => {
    this.appConfig = config;

    if (!this._isPlatformBrowser && this.appConfig.serverBypassProxy) {
      this.appConfig.server = this.appConfig.serverBypassProxy;
    }
    console.log('apiserver: ', this.appConfig.server);

    this.appConfigInfo.next(this.appConfig);
  }

  public OAuthTokenSubscribe = (func: any): ISubscription => {
    const sub = this.oAuthToken$.subscribe(func);
    // console.log('SecurityService:: UserInfoSubscribe');
    // one subscriber => load the data, if not loaded and not requested
    if (this.isPlatformBrowser) {
      if (!this.oAuthToken) {
        this.oAuthToken = sessionStorage.getItem('oAuthToken') || undefined;
      }
      this.oAuthTokenBS.next(this.oAuthToken || null);
    }
    return sub;
  }

  public getOAuthToken = () => {
    return this.oAuthToken;
  }

  public setOAuthToken = (oAuthToken: string) => {
    if (this.oAuthToken !== oAuthToken) {
      this.oAuthToken = oAuthToken;
      this.oAuthTokenBS.next(this.oAuthToken);

      if (this.isPlatformBrowser) {
        if (this.oAuthToken) {
          sessionStorage.setItem('oAuthToken', this.oAuthToken);

        } else {
          sessionStorage.removeItem('oAuthToken');
        }
      }
    }
  }

  public configurationSubscribe = (func: any): SubscriptionLike => {
    const sub = this.appConfigInfo$.subscribe(func);
    if (this.appConfig) {
      this.appConfigInfo.next(this.appConfig);
    }
    return sub;
  }

  public setLanguage = (language: string) => {
    console.log('lib.conf.setLanguage: ', language)
    this.language = language;
    this.languageBS.next(this.language);

    if (this.isPlatformBrowser) {
      if (this.language) {
        sessionStorage.setItem('language', this.language);

      } else {
        sessionStorage.removeItem('language');
      }
    }
  }

  public getLanguage = () => {
    if (isPlatformBrowser(this.platformId)) {
      const lg = sessionStorage.getItem('language');
      // if(lg) 
      this.language = lg || '';
    }
    return this.language;
  }
  //  public getConfig = () => { return this.appConfig }

  public LanguageSubscribe = (func: any): SubscriptionLike => {
    const sub = this.language$.subscribe(func);
    // console.log('SecurityService:: UserInfoSubscribe');
    // one subscriber => load the data, if not loaded and not requested
    // if (!this.language) {
    //   this.language = sessionStorage.getItem('language');
    // }
    const lang = this.getLanguage();
    console.log('lib.conf.LanguageSubscribe: ', lang);

    this.languageBS.next(lang);
    return sub;
  }
}
