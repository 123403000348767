<!-- <b>kljf lskdj klsdjfl</b>

<h1>[ {{'hello' | transloco }} ]</h1>
<h1>[ {{'bloco1.heelo2' | transloco }} ]</h1> -->

<div class="container-fluid" *ngIf="debugMode && hideTop === false">
    <div class="row">
        <div class="float-left col-11 pt-1">
            <form>
                <input type="file" placeholder="film foto" (change)="onAddDocument($event)" />
                <button type="button" class="btn btn-sm p-1 m-0" (click)="uploadFile()"
                    [disabled]="!(filename && contentArrayBuffer)">Upload</button>
            </form>
        </div>
        <div class="float-left col-1 pt-1">
            <button type="button" class="btn btn-sm float-end p-1 m-0" (click)="hideTop=true">X</button>
        </div>
    </div>
    <hr style="color: white;background-color: white;" />
</div>

<div *ngIf="isInicializing" class="text-center" style="height: 100vh;background-color: black;margin-top: 15vh;">
    <span class="spinner-border" style="width: 3rem; height: 3rem;" role="status" aria-hidden="true"></span>
</div>



    

<div [ngClass]="{'minHeightFull': isInicializing}" style="min-height: 100vh">
    <router-outlet></router-outlet>
</div>


<footer id='footer-pro'>
    <div class='container'>
        <div class='row'>
            <div class='col-md-8'>

                <h2>{{ 'footer.Newsletter' | transloco}}</h2>
                <form *ngIf="!emailSubscribed">
                    <div class="input-group mb-3" style="width: 85%;">
                        <input type="email" class="form-control form-controlSubscribe" placeholder="email_" #email
                            [style.border]="email.value === '' || !email.validationMessage ? '' : '1px solid red'"
                            aria-label="email_" aria-describedby="button-addon2">
                        <button class="btn btn-outline-secondary btnSubscribe" type="submit" id="button-addon2"
                            [disabled]="email.value !== '' && email.validationMessage !== ''"
                            (click)="newsletterSubscribe(email)">{{ 'footer.Subscribe' | transloco}}
                            <span *ngIf="isRegistingNewsletter" class="spinner-border spinner-border-sm" role="status"
                                aria-hidden="true"></span>
                        </button>
                    </div>
                </form>
                <div *ngIf="emailSubscribed" class="py-2 mb-3">
                    {{ 'footer.NewsletterThankyou' | transloco}}
                </div>
                <br />
                <br />
                <ul class="list-inline">
                    <li><a [routerLink]="['privacy']">{{ 'footer.Privacy' | transloco}}</a></li>
                    <li><a [routerLink]="['terms']">{{ 'footer.Terms' | transloco}}</a></li>
                </ul>
            </div>
            <div class='col-md-4'>
                <h2>{{ 'footer.GetInTouch' | transloco}}</h2>

                <ul class="list-inline">
                    <li>ARENES</li>
                    <li>Centre for Contemporary Theatre</li>
                    <li><a href='#'>arenes.pt</a></li>
                    <li><a href='mailto:education@joaogarciamiguel.com'>education@joaogarciamiguel.com</a></li>
                    <li>&nbsp;</li>
                    <li>whatsapp</li>
                    <li><a href='https://wa.me/351925609009' target="_blank">+351 918 773 362</a></li>
                    <li>&nbsp;</li>
                    <li>
                        Rua Brg. Miranda Palha, N.º16<br>
                        2560-310 Torres Vedras<br>
                        Portugal
                    </li>
                </ul>

            </div>
        </div>
    </div>

    <div class='container' style="border-top: 1px solid white">

        <div class="row ms-0 me-0 my-3 d-none d-xl-block">
            <div class='col-md-12 align-bottom'>
                ESTRUTURA FINANCIADA POR
            </div>
        </div>

        <div class='row ms-0 me-0 mt-4 mb-0 pb-0'>
            <div class='col-4 col-xl-2 d-xl-none d-block'>
                <div class='mtc-5'>
                    ESTRUTURA FINANCIADA POR
                </div>
            </div>
            <div class='col-4 col-xl-2'>
                <div class='footer-menu'>
                    <img _ngcontent-serverapp-c51="" class="ximgFooter img-fluid"
                        src="assets/images/footer/governo-portugal-cultura.png" xstyle="max-height: 100%;" xheight="150"
                        alt="" />
                </div>
            </div>
            <div class='col-4 col-xl-2'>
                <div class='footer-menu'>
                    <img _ngcontent-serverapp-c51="" class="img-fluid" src="assets/images/footer/dgartes.png"
                        xheight="150" alt="" />
                </div>
            </div>
            <div class='d-none d-xl-block col-xl-1'>
                <div class='footer-menu mx-auto'>
                    <img _ngcontent-serverapp-c51="" class="img-fluid" src="assets/images/footer/sep-footer.jpg"
                        xheight="150" alt="" />
                </div>
            </div>
            <div class='col-4 col-xl-3'>
                <div class='footer-menu'>
                    <img _ngcontent-serverapp-c51="" class="img-fluid" src="assets/images/footer/arenes.png"
                        xheight="150" alt="" />
                </div>
            </div>
            <div class='col-4 col-xl-2'>
                <div class='footer-menu' xstyle="background-color: gainsboro;">

                    <a href="https://joaogarciamiguel.com" target="_blank"><img _ngcontent-serverapp-c51=""
                            class="img-fluid" src="assets/images/footer/acompanhia.png" rel="noopener" xheight="150"
                            alt="" /></a>
                </div>
            </div>
            <div class='col-4 col-xl-2'>
                <div class='footer-menu'>
                    <a href="https://fluxtv.pt" target="_blank"><img _ngcontent-serverapp-c51="" class="img-fluid"
                            src="assets/images/footer/fluxtv.jpg" alt="Flux TV" xheight="150" rel="noopener" /></a>
                </div>
            </div>
        </div>

        <div class="row mt-0 pt-0" xstyle="background-color: aqua;">
            <div class='offset-xl-5 col-4 col-xl-3 text-center mt-0 pt-0' xstyle="background-color: gainsboro;">
                <ul class='social-icons-pro ml-0 pl-0 mt-0 pt-0' xstyle='margin: auto;'>
                    <li class='facebook-color'><a href='https://www.facebook.com/ArenesCCT' target='_blank'
                        rel="noopener"><i class='fab fa-facebook-f'></i></a></li>
                    <li class='instagram-color'><a href='https://www.instagram.com/arenes_cct/' target='_blank'
                            rel="noopener"><i class='fab fa-instagram'></i></a></li>
                </ul>
            </div>
            <div class='col-4 col-xl-2 text-center mt-0 pt-0'>
                <ul class='social-icons-pro ml-0 pl-0 mt-0 pt-0' xstyle='margin: auto;'>
                    <li class='facebook-color'><a href='https://www.facebook.com/Companhiajgm' rel="noopener"
                            target='_blank'><i class='fab fa-facebook-f'></i></a></li>
                    <li class='instagram-color'><a href='https://www.instagram.com/acompanhiajgm/' rel="noopener"
                            target='_blank'><i class='fab fa-instagram'></i></a></li>
                    <li class='instagram-color'><a href='https://www.youtube.com/@joaogarciamiguel' rel="noopener"
                            target='_blank'><i class='fab fa-youtube'></i></a></li>
                </ul>
            </div>
            <div class='col-4 col-xl-2 text-center mt-0 pt-0'>
                <ul class='social-icons-pro ml-0 pl-0 mt-0 pt-0' xstyle='margin: auto;'>
                    <li class='facebook-color'><a href='https://www.facebook.com/fluxtv.pt' target='_blank'
                            rel="noopener"><i class='fab fa-facebook-f'></i></a></li>
                    <li class='instagram-color'><a href='https://www.instagram.com/fluxtv.pt/ ' target='_blank'
                            rel="noopener"><i class='fab fa-instagram'></i></a></li>
                </ul>
            </div>
        </div>

        <div class='col-12'></div>
    </div>
</footer>


