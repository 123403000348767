<div class="container pt-4">
    <h2 class="post-list-heading mb-0">{{config?.title}}</h2>

    <div class="row g-2">

        <!-- -->
        <ng-container *ngIf="lista">
            <!-- <div class="mt-4" style="color: white;background-image: none;"><b>{{lista.length}}</b>
                {{"search.Results" | transloco }}</div> -->
            <div class="col-12 col-sm-6 col-md-4 col-lg-3 " *ngFor="let iten of lista">
                <a class="lnk" [routerLink]="['/', 'conteudo', iten.route, 1, 1]">
                    <div [ngStyle]="{ 'background-image': 'url(' + getImage(iten) + ')'}" class="bconteudo"
                        style="height: 250px; background-repeat: no-repeat;background-size: cover;">
                        <div>
                            <span class="fs18">{{iten.title}}</span><br>
                            <!-- <span class="fs15">
                                <ng-container *ngFor="let at of iten.attributes">{{at}} </ng-container>
                            </span> -->
                        </div>
                    </div>
                </a>
            </div>
        </ng-container>

        <!-- <pre>{{results | json}}</pre> -->
    </div>
</div>