import { FileInfo } from './../model/generic/fileInfo';
import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';
import { HttpHelperService } from './http-helper.service';

@Injectable()
export class SecurityDataService {

  constructor(private httpHelper: HttpHelperService) {
    // this.controllerName = 'v1/SecurityExtension';
  }

  public getToken() {
    return this.httpHelper.getToken();
  }

  public sendResetPassword = (username: string): Observable<boolean> => {
    // this.controllerName = 'security';
    const toSave = JSON.stringify({ username });
    return this.httpHelper.httpPost('v1/SecurityExtension', 'SendResetPassword', toSave);
  }

  public getUserAuthenticated = (): Observable<any> => {
    // this.controllerName = 'v1/SecurityExtension';
    return this.httpHelper.httpGet('v1/SecurityExtension', 'GetUserAuthenticated');
  }

  public getProfilePhoto = (photoId: number): Observable<FileInfo> => {
    // this.controllerName = 'v1/SecurityExtension';
    return this.httpHelper.httpGet('v1/SecurityExtension', 'GetProfilePhoto/' + photoId);
    // return this.httpHelper.httpGetFile('v1/SecurityExtension', 'GetProfilePhoto/' + photoId);
  }

  public saveUserAuthenticated = (profile: any): Observable<boolean> => {
    // this.controllerName = 'v1/SecurityExtension';
    const toSend = JSON.stringify(profile);
    return this.httpHelper.httpPost('v1/SecurityExtension', 'SaveUserAuthenticated', toSend);
  }

  public getUserEffectivePermissions = (): Observable<any[]> => {
    // this.controllerName = 'security';
    return this.httpHelper.httpGet('security', 'GetUserEffectivePermissions');
  }

  public createUserNotActivated = (email: string, name: string): Observable<boolean> => {
    // this.controllerName = 'v1/SecurityExtension';
    const toSend = JSON.stringify({ email, name });
    return this.httpHelper.httpPost('v1/SecurityExtension', 'CreateUserNotActivated', toSend);
  }

  public getNameEmail = (guid: string): Observable<boolean> => {
    // this.controllerName = 'v1/SecurityExtension';
    return this.httpHelper.httpGet('v1/SecurityExtension', 'GetNameEmail?guid=' + guid);
  }

  public activateUser = (guid: string, password: string): Observable<boolean> => {
    // this.controllerName = 'v1/SecurityExtension';
     const toSend = JSON.stringify({ guid, password });
     return this.httpHelper.httpPost('v1/SecurityExtension', 'ActivateUser', toSend);
  }


  public anonymize = (): Observable<any[]> => {
    // this.controllerName = 'security';
    return this.httpHelper.httpPost('v1/SecurityExtension', 'Anonymize');
  }

  public getDashboard = (): Observable<boolean> => {
    return this.httpHelper.httpGet('v1/SecurityExtension', 'GetDashboard');
  }

  public search = (filter: any): Observable<any[]> => {
    // this.controllerName = 'security';
    const toPost = JSON.stringify(filter);
    return this.httpHelper.httpPost('security', 'Search', toPost);
  }

  public searchUna = (filter: any): Observable<any[]> => {
    // this.controllerName = 'security';
    const toPost = JSON.stringify(filter);
    return this.httpHelper.httpPost('v1/SecurityExtension', 'UnaSearch', toPost);
  }

  public removeUna = (unaId: any): Observable<any[]> => {
    // this.controllerName = 'security';
    const toPost = JSON.stringify(unaId);
    return this.httpHelper.httpPost('v1/SecurityExtension', 'RemoveUna', toPost);
  }

  public resendAtivationEmail = (unaId: any): Observable<any[]> => {
    // this.controllerName = 'security';
    const toPost = JSON.stringify(unaId);
    return this.httpHelper.httpPost('v1/SecurityExtension', 'ResendAtivationEmail', toPost);
  }

  public changeState = (userId: number, active: boolean): Observable<any[]> => {
    // this.controllerName = 'security';
    const toPost = JSON.stringify({userId, active});
    return this.httpHelper.httpPost('security', 'ChangeState', toPost);
  }
}
