<div class="row mx-0" [ngClass]="config?.artigos?.class" xstyle="background-color: aquamarine;">
    <div *ngFor="let art of artigos; let idx=index; let last=last;" class="{{colArtigo}} artigo mb-4"
        [ngClass]="config?.artigos?.classArtigo" [ngClass]="{'barrinhaLeft': idx > 0 && last}" 
        xstyle="background-color: burlywood;">

        <div class="row" *ngIf="art.detalheArtigo.fichaTecnica !== null">
            <div class="offset-3 col-6 offset-sm-0 col-sm-2 d-none d-sm-block">
                <p [ngClass]="config?.artigos?.classArtigoBody" [innerHTML]="art.detalheArtigo.fichaTecnica"></p>
            </div>
            <div class="col-12 col-sm-10 pt-4">
                <h3 *ngIf="art.detalheArtigo.titulo" class="artigoTitulo" [innerHTML]="art.detalheArtigo.titulo">
                </h3>
                <h4 *ngIf="art.detalheArtigo.textoDestaque" class="artigoDestaque"
                    [ngClass]="config?.artigos?.classArtigoDestaque" [innerHTML]="art.detalheArtigo.textoDestaque">
                </h4>

                <p *ngIf="art.detalheArtigo.body" [ngClass]="config?.artigos?.classArtigoBody" class="artigoBody"
                    [innerHTML]="art.detalheArtigo.body"></p>
            </div>
            <div class="offset-3 col-6 offset-sm-0 col-sm-2 d-block d-sm-none">
                <p [ngClass]="config?.artigos?.classArtigoBody" [innerHTML]="art.detalheArtigo.fichaTecnica"></p>
            </div>
        </div>

        <div class="row  m-auto" *ngIf="art.detalheArtigo.fichaTecnica === null" xstyle="background-color: darkkhaki;">
            <h3 *ngIf="art.detalheArtigo.titulo" class="artigoTitulo" [ngClass]="config?.artigos?.classArtigoTitulo" [innerHTML]="art.detalheArtigo.titulo"></h3>
            <h4 *ngIf="art.detalheArtigo.textoDestaque" class="artigoDestaque"
                [ngClass]="config?.artigos?.classArtigoDestaque" [innerHTML]="art.detalheArtigo.textoDestaque">
            </h4>
            <p *ngIf="art.detalheArtigo.body" [ngClass]="config?.artigos?.classArtigoBody" class="mb-0"
                [innerHTML]="art.detalheArtigo.body"></p>
        </div>

    </div>
