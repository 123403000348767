import { Component } from '@angular/core';
import { ComponentLookup, ConfigurationService } from 'libs/vg-common';

@Component({
  selector: 'app-marquee',
  templateUrl: './marquee.component.html',
  styleUrls: ['./marquee.component.scss']
})
@ComponentLookup('MarqueeComponent')
export class MarqueeComponent {

  public config: any = null;
  public language = '';

  public paused = false;

  mouseEnter(div : string){
    // console.log("mouse enter : " + div);
    this.paused = true;
 }

 mouseLeave(div : string){
  //  console.log('mouse leave :' + div);
   this.paused = false;
 }

  constructor(private configurationService: ConfigurationService) {

  }
}
