import { HttpClient } from '@angular/common/http';
import {
  TRANSLOCO_LOADER,
  Translation,
  TranslocoLoader,
  TRANSLOCO_CONFIG,
  translocoConfig,
  TranslocoModule,
  // TranslocoService
} from '@ngneat/transloco';
import { Injectable, NgModule } from '@angular/core';
import { forkJoin, Observable, tap } from 'rxjs';
import { ConfigurationService } from './services/configuration.service';
import { TranslocoLoaderData } from '@ngneat/transloco/lib/transloco.loader';

@Injectable({ providedIn: 'root' })
export class TranslocoHttpLibLoader implements TranslocoLoader {
  constructor(private http: HttpClient, private configurationService: ConfigurationService) { 
// //    const a = 1;

    console.log('TranslocoHttpLibLoader');
  }
  // getTranslation(lang: string, data?: TranslocoLoaderData | undefined): Observable<Translation> | Promise<Translation> {
  //   throw new Error('Method not implemented.');
  // }

  getTranslation(lang: string):  Observable<Translation> {
    console.log('TranslocoHttpLibLoader.getTranslation');

    // const baseUrl = this.configurationService.environment.baseUrl;

    let baseUrl : string = '';
    if (this.configurationService.isPlatformBrowser) {
        baseUrl = this.configurationService.environment.baseUrl;
    }


    const obs = new Observable<Translation>((observer) => {
      return forkJoin({
        vgcommon: this.http.get<Translation>(`${baseUrl}/assets/vg-common/i18n/${lang}.json`),
        main: this.http.get<Translation>(`${baseUrl}/assets/i18n/${lang}.json`)
      }).subscribe(data => {
        const joined = {
          ...data.vgcommon,
          ...data.main
        };
        observer.next(joined);
      }, () => {
      });
    });

    return obs;
  }
}

@NgModule({
  exports: [TranslocoModule],
  providers: [
    {
      provide: TRANSLOCO_CONFIG,
      useValue: translocoConfig({
        availableLangs: ['en', 'pt'],
        defaultLang: 'pt',
        // Remove this option if your application doesn't support changing language in runtime.
        reRenderOnLangChange: true,
        // prodMode: environment.production,
        prodMode: false,
      })
    },
    { provide: TRANSLOCO_LOADER, useClass: TranslocoHttpLibLoader }
  ]
})
export class TranslocoRootLibModule { }
