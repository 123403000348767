import { Component, OnInit, VERSION } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { SecurityDataService } from '../../services/security-data.service';
import { SecurityService } from '../../services/security.service';
import { ComponentLookup } from '../dynamic-component/component-lookup';
import { faUser, faLock, faEyeSlash, faEye } from '@fortawesome/free-solid-svg-icons';
import { findIndex } from 'rxjs';

@Component({
  selector: 'lib-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
@ComponentLookup('LoginComponent')
export class LoginComponent implements OnInit {

  public model: any = { username: null, password: null };
  public appConfiguration = null;
  public isSaving = false;
  public errorMessage = '';
  public angularVersion = VERSION.full;
  public VERSION = VERSION;

  public config: any = null;

  private returnUrl?: string;
  public fieldTextType = false;

  public faUser = faUser; faLock = faLock;
  public faEye = faEye; faEyeSlash = faEyeSlash;

  constructor(private route: ActivatedRoute, private router: Router, private securityService: SecurityService, private securityDataService: SecurityDataService) { }

  ngOnInit(): void {
    this.returnUrl = this.route.snapshot.queryParams['returnUrl'] || this.config?.homeUrl || '/';
  }

  public onLogin() {
    this.errorMessage = '';
    if (!this.model.username && !this.model.password) {
      this.errorMessage = this.config?.errorEmptyFields?.label; // 'Preencha os campos, Utilizador e Palavra-passe';
      return;
    }

    this.isSaving = true;
    this.securityService
      .login(this.model.username, this.model.password)
      .subscribe((sucess: any) => {
        // console.log("login:success", sucess);
        if (typeof (sucess) === typeof (Boolean)) { // FO
          if (sucess) {
            this.router.navigate([this.returnUrl]);
            this.errorMessage = '';
          }
          else {
            this.errorMessage = this.config?.errorLoginFailled?.label; // 'Falha de Autenticação - O conjunto Utilizador/Palavra-passe, não foi validado;';
          }
          this.isSaving = false;
        } else {  // BO => returns un observable, to subscribe to get the efective permissions
          sucess.subscribe(() => {
            this.router.navigate([this.returnUrl]);
            this.errorMessage = '';
          });
        }
      },
        (error: any) => {
          this.isSaving = false;
          this.errorMessage = this.config?.errorLoginFailled?.label; // 'Falha de Autenticação - O conjunto Utilizador/Palavra-passe, não foi validado;';
          // this.errorMessage = error.message;
          console.log(error);
        },
        () => {
          this.isSaving = false;
        }
      );
  }


  // this.securityService
  // .login(this.model.username, this.model.password)
  // .subscribe(sucess => {
  //   if (sucess && sucess.subscribe(data => {
  //     this.router.navigate([this.returnUrl]);
  //     this.errorMessage = '';
  //   })) {

  //   } else {
  //     this.errorMessage = 'Falha de Autenticação - O conjunto Utilizador/Palavra-passe, não foi validado;';
  //   }
  //   this.isSaving = false;
  // },
  //   error => {
  //     this.isSaving = false;
  //     this.errorMessage = 'Falha de Autenticação - O conjunto Utilizador/Palavra-passe, não foi validado;';
  //     // this.errorMessage = error.message;
  //     console.log(error);
  //   });


  public esqueciPassword = () => {
    this.errorMessage = '';
    if (!this.model.username) {
      this.errorMessage = this.config?.errorForgotEmptyFields?.label; // 'Preencha o campo Utilizador';
      return;
    }

    this.isSaving = true;
    this.securityDataService
      .sendResetPassword(this.model.username)
      .subscribe(sucess => {
        if (sucess) {
          this.errorMessage = this.config?.errorForgotOk?.label; //'Email enviado.';
        } else {
          this.errorMessage = this.config?.errorForgotError?.label; //'Email não foi enviado.';
        }
      },
        error => {
          this.isSaving = false;
          this.errorMessage = error.message;
          console.log(error);
        }, () =>
        this.isSaving = false
      );
  }
}


