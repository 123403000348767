import { Injectable } from "@angular/core";
import { NotFoundComponent } from "../not-found/not-found.component";

// https://stackoverflow.com/questions/40115072/how-to-load-component-dynamically-using-component-name-in-angular2
export const ComponentLookupRegistry: Map<string, any> = new Map();

// LC: 6.Maio.2021 adicionar os componentes a uma rota 'dummy', pois se não são excluídos no build --prod

export const ComponentLookup = (key: string): any => {
    return (cls: any) => {
        console.log('ComponentLookup:' + key);
        ComponentLookupRegistry.set(key, cls);
    };
};

@Injectable({
  providedIn: 'root'
})
export class DynamicComponentsService {

  public getType = (componentName: string): any => {
    console.log('ComponentLookup:getType: ' + componentName);
    const classRef = ComponentLookupRegistry.get(componentName);
      return (classRef || NotFoundComponent);
  }

  public dump = () => {
    console.log('DynamicComponentsService', ComponentLookupRegistry);
  }
}
