import { Component, Input, OnChanges, OnInit } from '@angular/core';
import { DomSanitizer, Title } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import * as _ from 'lodash-es';
import { ComponentLookup, DataSourceService, WebApiService } from 'libs/vg-common';
import { ConfigurationService } from 'src/app/services/configuration.service';

@Component({
  selector: 'app-vayvo-conteudo-viewer',
  templateUrl: './vayvo-conteudo-viewer.component.html',
  styleUrls: ['./vayvo-conteudo-viewer.component.scss']
})

@ComponentLookup('VayvoConteudoViewerComponent')
export class VayvoConteudoViewerComponent implements OnInit, OnChanges {
  @Input() public config: any = null;
  @Input() public datasource: any = null;

  public videoSrc = null;
  public serie = false;
  public tituloGeral = null;


  public temporada = 1;
  public episodio = 1;
  public nextEpisode: any = null;
  public showVideo = true;

  constructor(private titleService: Title, private activatedRoute: ActivatedRoute, private router: Router, private configurationService: ConfigurationService, private webApiService: WebApiService, private dataSourceService: DataSourceService, private sanitizer: DomSanitizer) { }

  ngOnInit(): void {
  }

  ngOnChanges(): void {
    this.titleService.setTitle(this.datasource?.titulo + ' - Arenes');

    if (this.datasource?.video && this.videoSrc === null) {
      switch (this.datasource.video.providerVideo) {
        case 'vimeo':
          this.videoSrc = this.getVimeoVideo(this.datasource);
          break;
        case 'youtube':
          this.videoSrc = this.getYoutubeVideo(this.datasource);
          break;
        default:
          console.log('providerVideo, not supported');
      }
    }

    if (this.configurationService.isPlatformBrowser) {
      if (this.datasource?.imageId) {
        // get the file from the server / or cache
        this.webApiService.getFile(this.datasource.imageId).subscribe((data: any) => {
          this.datasource.image = data.contentArrayBufferBase64;
        });
      }
      if (this.datasource?.backgroundImageId) {
        // get the file from the server / or cache
        this.webApiService.getFile(this.datasource.backgroundImageId).subscribe((data: any) => {
          this.datasource.backgroundImage = data.contentArrayBufferBase64;
        });
      }
    }

    if (this.datasource?.tipoId === 2) {
      this.serie = true;
      this.tituloGeral = this.datasource.titulo;
      // get info sobre episódios
      if (this.configurationService.isPlatformBrowser) {

        this.activatedRoute.params.subscribe(params => {

          this.temporada = +params['temporada'];
          this.episodio = +params['episode'];

          this.dataSourceService.getData('season', { encodedtitle: this.datasource?.tituloUrlEncoded }).subscribe(data => {
            const season = _.find(data, ['temporadaId', this.temporada]);
            let episode = null;
            if (season) episode = _.find(season.episode, ['episodio', this.episodio]);

            // this.videoSrc = this.getVimeoVideo(episode);
            switch (episode.video.providerVideo) {
              case 'vimeo':
                this.videoSrc = this.getVimeoVideo(episode);
                break;
              case 'youtube':
                this.videoSrc = this.getYoutubeVideo(episode);
                break;
              default:
                console.log('providerVideo, not supported');
            }

            const idx = season.episode.indexOf(episode);
            let nepisode = (idx + 1) < season.episode.length ? season.episode[idx + 1] : null;

            if (nepisode === null) {
              // verifica sehá season seguinte
              const idxSeason = data.indexOf(season);
              const nseason = (idxSeason + 1) < data.length ? data[idxSeason + 1] : null;
              if (nseason) nepisode = nseason.episode[0]
            }

            this.nextEpisode = nepisode;
            if (this.nextEpisode?.imageId) {
              // get the file from the server / or cache
              this.webApiService.getFile(this.nextEpisode.imageId).subscribe((data: any) => {
                this.nextEpisode.image = data.contentArrayBufferBase64;
              });
            }
          });
        });
      }
    }
  }

  private getVimeoVideo = (source: any): any => {
    if (source?.video) {
      const url = 'https://player.vimeo.com/video/' + source?.video?.identificador + '?h=1e16ce7aef&title=0&byline=0&portrait=0&autoplay=1';
      const urls = this.sanitizer.bypassSecurityTrustResourceUrl(url);

      // console.log('getVimeoVideo');
      return urls;
    }
    return null;
  }

  private getYoutubeVideo = (source: any): any => {
    if (source?.video) {
      const url = 'https://www.youtube.com/embed/' + source?.video?.identificador + '?autoplay=1';
      const urls = this.sanitizer.bypassSecurityTrustResourceUrl(url);

      // console.log('getYoutubeVideo');
      return urls;
    }
    return null;
  }

  public getImage = (element: any, nitem: number) => {

    if (element) {
      if (nitem == 0) {  // background
        if (element && element.backgroundImageId)
          return ('data:image/jpeg;base64,' + element.backgroundImage);
        else
          return null;

      } else {
        if (element && element.imageId)
          return ('data:image/jpeg;base64,' + element.image);
        else
          return null;
      }
    }
    else
      return null;
  }

  public xClicked = () => {
    if (this.serie && this.showVideo == true && this.nextEpisode) {
      this.showVideo = false;
    } else
      this.router.navigate(['/', this.config.routeconteudo, this.datasource.tituloUrlEncoded, 1, 1]);
  }

  public nextClicked = () => {
    this.showVideo = true;
    this.router.navigate(['/', this.config.route, this.datasource?.tituloUrlEncoded, this.nextEpisode.temporada, this.nextEpisode.episodio]);
    // this.ngOnChanges();
  }

}
