<div class="bg-light min-vh-100 d-flex flex-row align-items-center">
  <div class="container">
    <div class="row justify-content-center">
      <div class="col-md-8">
        <div class="card-group mb-0">
          <div class="card p-4">
            <div class="card-body">
              <form novalidate>
                <h1>{{config?.loginLabel?.label}}</h1>
                <p class="text-muted">{{config?.loginAdditionalLabel?.label}}</p>
                <div class="row">
                  <div class="input-group mb-3 col-12">
                    <span class="input-group-addon" style="min-width: 25px;padding-top: 6px;">
                      <fa-icon style="color: grey;" [icon]="faUser"></fa-icon>
                    </span>
                    <input type="text" class="form-control vgForms" placeholder="{{config?.loginPlaceholder?.label}}"
                      name="username" #username autofocus tabindex="1" [(ngModel)]="model.username">
                  </div>
                </div>
                <div class="row">
                  <div class="input-group mb-4 col-12">
                    <span class="input-group-addon" style="min-width: 25px;padding-top: 6px;">
                      <fa-icon style="color: grey;" [icon]="faLock"></fa-icon>
                    </span>
                    <input [type]="fieldTextType ? 'text' : 'password'" class="form-control vgForms"
                      placeholder="{{config?.passwordPlaceholder?.label}}" name="password" tabindex="2"
                      [(ngModel)]="model.password">
                    <div class="input-group-append mx-auto my-auto" style="min-width: 30px;padding-left: 4px;">
                      <fa-icon style="color: grey;" [icon]="fieldTextType ? faEye : faEyeSlash" (mouseover)="fieldTextType=true"
                        (mouseleave)="fieldTextType=false"></fa-icon>
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-5">
                    <button type="submit" class="btn btn-primary px-4" tabindex="3"
                      (click)="onLogin()">{{config?.loginButton?.label}}
                      <span *ngIf="isSaving" class="spinner-border spinner-border-sm" role="status"
                        aria-hidden="true"></span>
                    </button>
                  </div>
                  <div class="col-7 text-right">
                    <button type="button" class="btn btn-link px-0"
                      (click)="esqueciPassword()">{{config?.passwordForgot?.label}}
                    </button>
                  </div>
                </div>
                <div class="row">
                  <div class="col-12 error" style="padding-top: 10px;">
                    {{errorMessage}}
                  </div>
                </div>

              </form>
            </div>
          </div>
          <div class="card text-white bg-primary py-5 d-md-down-none" style="width:44%">
            <div class="card-body text-center">
              <div>
                <h2>{{config?.application?.label}}</h2>
                <p>{{config?.applicationDescription?.label}}</p>
                <br /><br />
                <small>{{config?.applicationVersion?.label}}</small><br />
                <small>&copy; vGraf - 1996-2022</small><br />
                <small>ng {{angularVersion}}</small>
              </div>
            </div>
          </div>
        </div>
        <!-- config: <pre>{{config | json}}</pre> -->
      </div>
    </div>

  </div>
</div>