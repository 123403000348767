import { Injectable } from "@angular/core";

import { Observable, of } from 'rxjs';
import { FileInfo } from "../model/generic/fileInfo";

import { ConfigurationService } from "./configuration.service";
import { HttpHelperService } from "./http-helper.service";

@Injectable()
export class WebApiService {
    private controllerName: string;

    constructor(private httpHelper: HttpHelperService, private configuration: ConfigurationService) {
        this.controllerName = 'v1/WebApi';
    }

    public uploadFile = (file: FileInfo): Observable<any> => {
        const data = JSON.stringify(file);
        return this.httpHelper.httpPost(this.controllerName, 'uploadFile', data);
    }

    public getFile = (fileId: number): Observable<any> => {
        return this.httpHelper.httpGetFile(this.controllerName, 'getFile/' + fileId);
    }

    public getImageFile = (fileId: number, height: number, width: number): Observable<any> => {
        return this.httpHelper.httpGetFile(this.controllerName, 'getImageFile/' + fileId + '/' + height + '/' + width);
    }

    public registerNewsletter = (email: string): Observable<boolean> => {
        const data = JSON.stringify(email);
        return this.httpHelper.httpPost(this.controllerName, 'registerNewsletter', data);
    }
}