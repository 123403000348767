import { Component, Input, OnInit } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { ComponentLookup, DataSourceService, WebApiService } from 'libs/vg-common';
import { ConfigurationService } from 'src/app/services/configuration.service';
import { Title } from "@angular/platform-browser";
import { ViewChild } from '@angular/core';
import { OnChanges } from '@angular/core';
import { AfterViewChecked } from '@angular/core';

declare var jQuery: any;

@ComponentLookup('VayvoViewerComponent')
@Component({
  selector: 'app-vayvo-viewer',
  templateUrl: './vayvo-viewer.component.html',
  styleUrls: ['./vayvo-viewer.component.scss']
})
export class VayvoViewerComponent implements OnInit, OnChanges, AfterViewChecked {
  @ViewChild('flexsliderreldiv', { static: false }) flexsliderx: any;
  @Input() public config: any = null;
  @Input() public datasource: any = null;
  private jsBinded = false;

  public videoSrc = null;
  public serie = false;
  public temporadas = null;
  public curTemporada: any = null;

  constructor(private titleService: Title, private configurationService: ConfigurationService, private webApiService: WebApiService, private dataSourceService: DataSourceService, private sanitizer: DomSanitizer) { }

  ngOnInit(): void {
  }

  ngOnChanges(): void {

    this.titleService.setTitle(this.datasource?.titulo + ' - Arenes');

    if (this.datasource?.video && this.videoSrc === null) {
      this.videoSrc = this.getVimeoVideo();
    }

    if (this.configurationService.isPlatformBrowser) {
      if (this.datasource?.imageId && !this.datasource.image) {
        // get the file from the server / or cache
        this.webApiService.getFile(this.datasource.imageId).subscribe((data: any) => {
          this.datasource.image = data.contentArrayBufferBase64;
        });
      }
      if (this.datasource?.backgroundImageId && !this.datasource.backgroundImage) {
        // get the file from the server / or cache
        this.webApiService.getFile(this.datasource.backgroundImageId).subscribe((data: any) => {
          this.datasource.backgroundImage = data.contentArrayBufferBase64;
        });
      }

      if (this.datasource?.elenco) {
        // fotos do elenco
        this.datasource.elenco.forEach((elem: any) => {
          if (elem?.avatarFileManagerId && !elem.image) {
            this.webApiService.getFile(elem.avatarFileManagerId).subscribe((data: any) => {
              elem.image = data.contentArrayBufferBase64;
            });
          }
        });
      }

      if (this.datasource?.relacionados?.lista) {
        // force reload
        if (this.jsBinded) {
          location.href = location.href;
        } else {
          this.jsBinded = false;
          this.datasource.relacionados.lista.forEach((elem: any) => {
            if (elem?.backgroundImageId && !elem.backgroundImage) {
              this.webApiService.getFile(elem.backgroundImageId).subscribe((data: any) => {
                elem.backgroundImage = data.contentArrayBufferBase64;
              });
            }
          });
        }
      }
    }

    if (this.datasource?.tipoId === 2) {
      this.serie = true;
      // get info sobre episódios
      if (this.configurationService.isPlatformBrowser) {

        this.dataSourceService.getData('season', { encodedtitle: this.datasource?.tituloUrlEncoded }).subscribe(data => {
          this.temporadas = data;
          data.forEach((temporada: any) => {
            if (!this.curTemporada) this.curTemporada = temporada;
            temporada.episode.forEach((episode: any) => {
              if (episode.imageId) {
                this.webApiService.getFile(episode.imageId).subscribe((data: any) => {
                  episode.image = data.contentArrayBufferBase64;
                });
              }
            })
          });
        });
      }
    }
  }

  ngAfterViewChecked() {
    if (this.configurationService.isPlatformBrowser) {
      if (this.datasource && this.jsBinded === false && this.flexsliderx?.nativeElement) {
        // console.log('ngAfterViewChecked');
        // setTimeout(() => this.bindJQuery(), 1000);
        this.bindJQuery();
        this.jsBinded = true;
      }
    }
  }

  private bindJQuery() {


    if (this.flexsliderx?.nativeElement) {
      // console.log('conteudos: bindJQuery, this.datasource:', this.datasource?.relacionados);
      jQuery(this.flexsliderx.nativeElement).flexslider({
        animation: "slide",
        animationLoop: false,
        itemWidth: 350,
        itemMargin: 5,
        prevText: "",
        nextText: "",
        controlNav: false
      });
      // const a = 1;
    }

  }

  private getVimeoVideo = (): any => {
    if (this.datasource?.video) {
      const url = 'https://player.vimeo.com/video/' + this.datasource?.video?.identificador + '?h=1e16ce7aef&title=0&byline=0&portrait=0';
      const urls = this.sanitizer.bypassSecurityTrustResourceUrl(url);

      // console.log('getVimeoVideo');
      return urls;
    }
    return null;
  }


  public getImage = (element: any, nitem: number) => {

    if (element) {
      if (nitem == 0) {  // background
        if (element && element.backgroundImageId && element.backgroundImage)
          return ('data:image/jpeg;base64,' + element.backgroundImage);
        else
          return null;

      } else {
        if (element && element.image)
          return ('data:image/jpeg;base64,' + element.image);
        else
          return null;
      }
    }
    else
      return null;
  }
}
