<!-- <app-vayvo-conteudos-slider [lista]="datasource?.lista" [config]="config" *ngIf="config?.viewer === 'slider'"></app-vayvo-conteudos-slider> -->
<!--<app-vayvo-conteudos-lista [lista]="datasource?.lista" [config]="config" *ngIf="config?.viewer === 'lista'"></app-vayvo-conteudos-lista> -->


<app-vayvo-slider [config]="config" [datasource]="datasourceToBind" [firstElement]="firstElement" (elementChanged)="elementChanged($event)"></app-vayvo-slider>
<app-conteudo-text [config]="config" [datasource]="datasourceToBind" [currentElement]="currentElement"></app-conteudo-text>

<!-- <h6 style="xbackground-color: white;">currentElement:{{currentElement | json}}</h6> -->

<!-- <pre>config: {{config | json}}</pre> -->
<!-- 

datasource: -->
<!-- <pre>{{datasource | json }}</pre>  -->