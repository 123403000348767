import { Component, ComponentFactoryResolver, EventEmitter, Input, OnInit, Output, QueryList, ViewChild, ViewChildren, ViewContainerRef, ViewEncapsulation } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ConfigurationService } from '../../services/configuration.service';
import { DynamicComponentsService } from '../dynamic-component/component-lookup';
import { ComponentHostDirective } from '../../directives/component-host/componentHost.directive';

@Component({
  selector: 'lib-generic-content',
  templateUrl: './generic-content.component.html',
  styleUrls: ['./generic-content.component.css'],
  encapsulation: ViewEncapsulation.None
})
export class GenericContentComponent implements OnInit {
  @ViewChild(ComponentHostDirective, { read: ViewContainerRef }) public appComponentHost: ViewContainerRef | undefined;
  @Output() isChanged: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() blockSaved: EventEmitter<any> = new EventEmitter<any>();

  constructor(private activatedRoute: ActivatedRoute, private router: Router,
    public configurationService: ConfigurationService) { 
      
      console.log('GenericContentComponent ... CTOR');
    }
    private subscribedHandles = new Map<string, any>();

  public config: any;

  ngOnInit(): void {
    this.activatedRoute.data.subscribe(data => {
      this.config = data;

      // setTimeout(() => {
      //   this.loadComponents();
      // }, 0);
    });
  }

  public ischanged = (data: any) => {
    console.log('GenericContentComponent: Is changed');
    this.isChanged.emit(data);
  }

  public isblockSaved = (data: any) => {
    console.log('GenericContentComponent: Is blockSaved');
    this.blockSaved.emit(data);
  }

  // private loadComponents() {
  //   if (this.appComponentHost) {
  //     const type = this.dynamicComponentsService.getType('VayvoMenuComponent');

  //     if (type) {
  //       const viewContainerRef = this.appComponentHost;
  //       const data= (<any>this.appComponentHost)?.data;
  //       console.log('data', this.appComponentHost);
  //       viewContainerRef.clear();
  //       const componentRef = viewContainerRef.createComponent(type);

  //       const component = componentRef.instance as any;
  //       component.config = data;
  //     }
  //   }
  // }


}
