import { Component, Input } from '@angular/core';
import { ComponentLookup } from 'libs/vg-common';

@Component({
  selector: 'app-vayvo-slider-home',
  templateUrl: './vayvo-slider-home.component.html',
  styleUrls: ['./vayvo-slider-home.component.scss']
})
@ComponentLookup('VayvoSliderHomeComponent')
export class VayvoSliderHomeComponent {
  @Input() public config: any = null;
  @Input() public datasource: any = null;
}
