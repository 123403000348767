import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { HttpClientModule } from '@angular/common/http';
import { CommonModule } from '@angular/common';

import { TranslocoModule } from '@ngneat/transloco';
import { VgCommonModule, ConfigurationService as LibConfigurationService, TranslocoRootLibModule } from 'libs/vg-common';
import { AppRoutingModule } from './app-routing.module';
import { environment } from 'src/environments/environment';
import { ConfigurationService } from './services/configuration.service';
import { VayvoConteudosComponent } from './components/vayvo-conteudos/vayvo-conteudos.component';
import { VayvoScrollTopComponent } from './components/vayvo-scroll-top/vayvo-scroll-top.component';
import { VayvoMenuComponent } from './components/vayvo-menu/vayvo-menu.component';
import { VayvoSliderComponent } from './components/vayvo-slider/vayvo-slider.component';
import { VayvoViewerComponent } from './components/vayvo-viewer/vayvo-viewer.component';
import { P404Component } from './p404/p404.component';
import { AppComponent } from './app.component';
import { VayvoConteudoViewerComponent } from './components/vayvo-conteudo-viewer/vayvo-conteudo-viewer.component';
import { VayvoSearchComponent } from './components/vayvo-search/vayvo-search.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { VayvoResultadosComponent } from './components/vayvo-resultados/vayvo-resultados.component';
import { VayvoEntidadeComponent } from './components/vayvo-entidade/vayvo-entidade.component';
import { VayvoConteudosListaComponent } from './components/vayvo-conteudos-lista/vayvo-conteudos-lista.component';
import { VayvoConteudosSliderComponent } from './components/vayvo-conteudos-slider/vayvo-conteudos-slider.component';
import { VayvoSliderHomeComponent } from './components/vayvo-slider-home/vayvo-slider-home.component';
import { ConteudoTextComponent } from './components/conteudo-text/conteudo-text.component';
import { MarqueeComponent } from './components/marquee/marquee.component';
import { NgxGoogleAnalyticsModule, NgxGoogleAnalyticsRouterModule } from 'ngx-google-analytics';
import { MetadataService } from './services/metadata.service';

@NgModule({
  declarations: [
    AppComponent,
    P404Component,
    VayvoMenuComponent,
    VayvoSliderComponent,
    VayvoViewerComponent,
    VayvoConteudoViewerComponent,
    VayvoConteudosComponent,
    VayvoScrollTopComponent,
    VayvoSearchComponent,
    VayvoResultadosComponent,
    VayvoEntidadeComponent,
    VayvoConteudosListaComponent,
    VayvoConteudosSliderComponent,
    VayvoSliderHomeComponent,
    ConteudoTextComponent,
    MarqueeComponent
  ],
  imports: [
    BrowserModule.withServerTransition({ appId: 'serverApp' }),
    AppRoutingModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    CommonModule,
    TranslocoRootLibModule,
    TranslocoModule,
    NgbModule,
    VgCommonModule.forRoot(environment),
    NgxGoogleAnalyticsModule.forRoot('G-SKSJH64KFN'),
    NgxGoogleAnalyticsRouterModule
  ],
  providers: [
    ConfigurationService,
    LibConfigurationService,
    MetadataService
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
