import { AfterViewChecked, AfterViewInit, Component, ElementRef, Input, OnChanges, OnInit, Optional, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ComponentLookup, WebApiService } from 'libs/vg-common';
import { ConfigurationService } from 'src/app/services/configuration.service';
import * as _ from 'lodash-es';
import { Meta, Title } from '@angular/platform-browser';

declare var jQuery: any;

@Component({
  selector: 'app-vayvo-conteudos',
  templateUrl: './vayvo-conteudos.component.html',
  styleUrls: ['./vayvo-conteudos.component.scss']
})
@ComponentLookup('VayvoConteudosComponent')
export class VayvoConteudosComponent implements OnInit, OnChanges {
  // @ViewChild('flexsliderdiv', { static: true }) flexsliderx: any;
  @Input() public config: any = null;
  @Input() public datasource: any = null;
  // private jsBinded = false;

  public firstElement = 0;
  public currentElement = 0;
  public conteudoId: number | undefined = undefined;
  public datasourceToBind: any = null;

  constructor(private router: Router, private activatedRoute: ActivatedRoute, private webApiService: WebApiService,
     private configurationService: ConfigurationService, private metaService: Meta, private titleService: Title
     ) {
    // this.flexsliderx.nativeElement = {};

    this.activatedRoute.params.subscribe((params: any) => {
      const id = +params['id'];
      if (isNaN(id))
        this.conteudoId = undefined;
      else
        this.conteudoId = id;

      this.processarLista();
      console.log('id', id);
    });
  }

  ngOnChanges(): void {
    if (this.datasource?.lista) {
      // get images
      this.datasource.lista.forEach((element: any) => {
        if (element.backgroundImageId && element.backgroundImage == null) {
          // get the file from the server / or cache
          if (this.configurationService.isPlatformBrowser) {
            this.webApiService.getImageFile(element.backgroundImageId, 350, 32000).subscribe((data: any) => {
              element.backgroundImage = data.contentArrayBufferBase64;
            });
          }
        }
      });
      this.processarLista();
      this.elementChanged(this.datasource.lista[0]);
    }
  }

  private processarLista = () => {
    if (this.datasource) {
      // identifica o conteudo inicial se lista > 1
      if (this.datasource.lista.length > 1 && this.config.sliderStart === 'random') {
        this.firstElement = Math.floor(Math.random() * this.datasource.lista.length);
        // this.firstElement = 1;
      } else {
        this.firstElement = 0;
      }

      this.datasourceToBind = { id: this.datasource.id, name: this.datasource.name };
      if (this.conteudoId !== undefined) {
        const lista = _.find(this.datasource.lista, ['conteudoId', this.conteudoId]);
        this.datasourceToBind.lista = [lista];
      } else {
        this.datasourceToBind.lista = this.datasource.lista;
      }
    }
  }

  public elementChanged = (event: any) => {
    // console.log('event: ', event);
    this.currentElement = _.find(this.datasource.lista, ['conteudoId', event.conteudoId]);

    // this.titleService.setTitle('title:' +  event.conteudoId);

    // <meta property="og:title" content="Arenes - Centre for Contemporary Theatre" />
    // <meta property="og:url" content="https://arenes.pt" />
    // <!-- <meta property="og:type" content="article" /> -->
    // <meta property="og:image" content="https://arenes.pt/assets/images/arenes_og.png" />

    
     this.metaService.updateTag({property: 'og:title', content: event.title});
     this.metaService.updateTag({property: 'og:url', content: 'https://arenes.pt' + this.router.url});
    //  this.metaService.updateTag({property: 'og:image', content: event.conteudoId});

     console.log('og:title', event.title);
     console.log('og:url', 'https://arenes.pt' + this.router.url);
    //  console.log('og:image', this.router.url);

  }

  ngOnInit(): void {
  }

  // ngAfterViewChecked() {
  //   if (this.configurationService.isPlatformBrowser) {
  //     if (this.datasource && this.jsBinded === false) {
  //       // console.log('ngAfterViewChecked');
  //       this.bindJQuery();
  //       this.jsBinded = true;
  //     }
  //   }
  // }

  // private bindJQuery() {
  //   // console.log('conteudos: bindJQuery');

  //   jQuery(this.flexsliderx.nativeElement).flexslider({
  //     animation: "slide",
  //     animationLoop: false,
  //     itemWidth: 250,
  //     itemMargin: 5,
  //     prevText: "",
  //     nextText: "",
  //     controlNav: false
  //   });
  // }

  public getTruncate = (texto: string, length: number) => {
    const t = texto.replace(/(<([^>]+)>)/ig, '')
    return t.substring(0, Math.min(length, t.length)) + ' ...';
  }

  // public getImage = (element: any) => {
  //   if (this.configurationService.isPlatformBrowser) {
  //     if (element && element.backgroundImageId) {
  //       if (element.backgroundImage)
  //         return ('data:image/jpeg;base64,' + element.backgroundImage);
  //       else
  //         return '';
  //     }
  //     else
  //       return 'assets/images/Gray1700x1133.png';
  //   } else
  //     return 'assets/images/Gray1700x1133.png';
  // }

  public xClick = (iten: any) => {
    // this.router.navigate(['/', this.config.route, iten.route, 1, 1]);
    this.router.navigateByUrl('/' + this.config.route + '/' + iten.route + '/1/1').then(() => {
      if (this.configurationService.isPlatformBrowser) {
        window.location.reload();
      }
    });
  }

}
