import { Component, Input, OnChanges } from '@angular/core';
import { ComponentLookup, WebApiService } from 'libs/vg-common';
import * as _ from 'lodash-es';

@Component({
  selector: 'app-conteudo-text',
  templateUrl: './conteudo-text.component.html',
  styleUrls: ['./conteudo-text.component.scss']
})
@ComponentLookup('ConteudoTextComponent')
export class ConteudoTextComponent implements OnChanges {
  @Input() public config: any = null;
  @Input() public datasource: any = null;
  @Input() public currentElement: any = null;

  public artigos = <any>[];
  public colArtigo = "col-12"

  constructor(private webApiService: WebApiService) {
  }

  ngOnChanges(): void {
    if (this.datasource?.lista) {
      let curElement = null;
      if (this.currentElement) {
        curElement = this.currentElement;
      } else {
        curElement = this.datasource.lista[0];
      }

      if (curElement.detalheItem) {
        this.artigos = _.filter(curElement.detalheItem, (o: any) => {
          return o.detalheArtigo != null;
        });

        const nArtigos = this.artigos.length;
        const coln = 12 / nArtigos;
        this.colArtigo = 'col-md-' + coln;
      }
    }
  }


}
