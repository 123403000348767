<!-- <div xstyle="background-color: white;"> showVideo: {{showVideo}}</div> -->

<div style="position: relative;">
    <a href="#" onclick="return false;" [routerLink]="['/']">
        <img src="/assets/images/arenes-logo.png" class="progression-studios-default-logo">
    </a>
    <button type="button" xstyle="background-color: aqua;" class="btn btn-sm float-end me-1 mt-2" (click)="xClicked()" >X</button>
</div>


<div  *ngIf="!showVideo" id="video-page-title-pro" class="video-embed-height-adjust" [ngStyle]="{ 'background-image': 'url(' + getImage(datasource, 0) + ')'}">

    <div id="video-embedded-container">
        <div style="padding:16.25% 0 0 0;position:relative;">
        
            <div class="mx-auto my-auto" style="width: 75%;margin-top: 10%;">
                <h2>{{tituloGeral}}</h2>
                <div class="vayvo-progression-video-season-container" *ngIf="datasource && nextEpisode">
            
                     <ul class="progression-studios-episode-list-main">
                        <li class="progression-studios-episode-list-item">
                             <div class="progression-episode-list-flex">
                            <div class="progression-studios-episode-image-container">
                                    <div class="progression-episode-list-left-margin">
                                        <a class="afterglow pointer" (click)="nextClicked()">
                                            <div class="progression-episode-list-image-container">
                                                <img src="{{getImage(nextEpisode, 1) || 'assets/images/Gray1700x1133.png' }}" alt="{{nextEpisode.titulo}}">
                                                <div class="progression-episode-list-overlay-play">
                                                    <i class="fas fa-play"></i>
                                                </div>
                                            </div>
                                        </a>
                                    </div>
                                </div>
            
                                <div class="progression-studios-episode-right-container">
                                    <div class="progression-episode-list-right-margin">
                                        <a class="afterglow pointer" (click)="nextClicked()">
                                            <h2 class="progression-episode-list-title">S{{nextEpisode.temporada}} | {{nextEpisode.titulo}}</h2>
                                        </a>
            
                                        <ul class="progression-studios-episode-list-meta">
                                            <li class="progression-episode-list-meta-release-date" *ngIf="nextEpisode.dataLancamento">{{nextEpisode.dataLancamento | date : 'dd MMM yyyy'}}</li>
                                            <li class="progression-episode-list-meta-release-date" *ngIf="nextEpisode.duracao">{{nextEpisode.duracao }} min.</li>
                                        </ul>
            
                                        <div class="progression-episode-list-short-description">{{nextEpisode.sinopse}}</div>
                                    </div>
                                </div>
            
                                <!-- <div class=".clearfix"></div> -->
                            </div>
                        </li>
                    </ul> 
                    <!-- <div class=".clearfix"></div> -->
                </div>
            </div>
        </div>
    </div>
    <div id="video-page-title-gradient-base"></div>
</div>




<div style="padding:56.25% 0 0 0;position: relative;" *ngIf="showVideo && videoSrc">
    <iframe [src]="videoSrc" style="position:absolute;top:0;left:0;width:100%;height:100%;" frameborder="0" allow="autoplay; picture-in-picture" allowfullscreen></iframe>
</div>

<!-- datasource: <pre>{{datasource | json}}</pre> -->
