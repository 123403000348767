<!-- <div style="background-color: white;"> activeSiteSection: {{isSectionActive('series')}}</div> -->
<header id="masthead-pro" class="sticky-header" style="background: url('../../../assets/images/bg-menu.png') repeat;">
    <div class="header-container" xstyle="background-color: cyan;">

        <h1 id="logo-pro" class="logo-inside-nav-pro noselect" *ngIf="config.menu?.brand">
            <a href="#" onclick="return false;" [routerLink]="['/']" title="{{config.menu?.brand.label?.text}}">
                <img src="{{config.menu?.brand.label?.image}}" alt="{{config.menu?.brand.label?.text}}"
                    class="progression-studios-default-logo progression-studios-hide-mobile-custom-logo">
            </a>
        </h1>

        <nav id="site-navigation-pro" class="nav-menu" xstyle="background-color: yellow;">
            <ul class="sf-menu">
                <li class="normal-item-pro" *ngFor="let iten of config.menu?.itens"
                    [ngClass]="{'current-menu-item': isSectionActive(iten.route, iten.anchor)}"
                    style="margin-right: 25px;">
                    <a href="" title="{{iten.label?.text}}" onclick="return false;" [routerLink]="[iten.route]"
                        class="{{iten.label?.class}}">{{iten.label?.text}}
                    </a>
                </li>
            </ul>
        </nav>

        <div id="mobile-bars-icon-pro" class="noselect"><i class="fas fa-bars"></i></div>

        <div class="float-end ms-1 me-1 headerR" style="margin-top: 40px;xbackground-color: aquamarine;">
            <!-- <a href="/assets/files/ApplicationForm_{{language == 'pt' ? 'PT' : 'EN'}}.pdf" target="_blank" *ngFor="let iten of config.menu?.itensEnd" class="destaque-menu" gaEvent="Apply_click" gaCategory="Apply">{{iten.label?.text}}</a> -->
            <a href="" *ngFor="let iten of config.menu?.itensEnd" [routerLink]="[iten.route]" class="destaque-menu" gaEvent="Apply_click" gaCategory="Apply">{{iten.label?.text}}</a>


            <button class="btn btn-sm cBtnLang" [ngClass]="{active: language == 'pt'}" type="button"
                (click)="btLang('pt')" title="PT">PT</button>
            <span class="btn btn-sm cBtnLang nopointer">|</span>
            <button class="btn btn-sm cBtnLang me-2" [ngClass]="{active: language == 'en'}" type="button"
                (click)="btLang('en')" title="EN">EN</button>
        </div>

        <div class="clearfix"></div>
    </div>


    <nav id="mobile-navigation-pro" >

        <ul id="mobile-menu-pro" class="collapsed">
            <li *ngFor="let iten of config.menu?.itens">
                <a href="{{iten.route}}"><i class="fas {{iten.label?.icon}}"></i>{{iten.label?.text}}</a>

                <ng-container *ngIf="iten.children">
                    <ul class="sub-menu" #submenu>
                        <li *ngFor="let itc of iten.children"><a href="{{itc.route}}"><i class="fas"></i>{{itc.label?.text}}</a></li>
                    </ul> 
                    <span class="sub-collapser-custom" [ngClass]="{'expanded': subMenuOpen}" (click)="subMenuClick($event, submenu)"><i></i></span>
                </ng-container>
            </li>
            <!-- <li>
                <a href="#" [routerLink]="['/instructors/all']"><i class="fas"></i>INSTRUCTORS</a>
                <ul class="sub-menu" #submenu>

                    <li><a href="#" [routerLink]="['/instructors', '1']"><i class="fas"></i>INSTRUCTORS1</a></li>
                    <li><a href="#" [routerLink]="['/instructors', '2']"><i class="fas"></i>INSTRUCTORS2</a></li>
                    <li><a href="#" [routerLink]="['/instructors', '3']"><i class="fas"></i>INSTRUCTORS3</a></li>
                </ul> 
                <span class="sub-collapser-custom" [ngClass]="{'expanded': subMenuOpen}" (click)="subMenuClick($event, submenu)"><i></i></span>
            </li> -->
            <li *ngFor="let iten of config.menu?.itensEnd">
                <a href="/assets/files/ApplicationForm_{{language == 'pt' ? 'PT' : 'EN'}}.pdf" target="_blank"><i class="fas {{iten.label?.icon}}"></i>{{iten.label?.text}}</a>
            </li>
        </ul>

        <!-- <div id="search-mobile-nav-pro active-search-icon-pro">
            <input type="text" placeholder="Search for Movies or TV Series" aria-label="Search" (click)="search()">
        </div> -->

    </nav>
    <!-- <div id="progression-studios-header-shadow"></div> -->
</header>
<!-- 
config:
<pre style="background-color: white;">{{ config | json}}</pre> -->


<!-- <div class="container" style="height: 200px;">xxxxxxx<iframe src='https://player.vimeo.com/video/545548583?h=1e16ce7aef&title=0&byline=0&portrait=0' style='position:absolute;top:0;left:0;width:100%;height:100%;' frameborder='0' allow='autoplay; fullscreen; picture-in-picture' allowfullscreen></iframe>yyyyyyyyy</div>
 -->


<!--  
 <div class="row d-flex flex-row" xstyle="width: 100%;">
    <div class="col-3 d-flex justify-content-start">
        <h1 id="logo-pro" class="logo-inside-nav-pro noselect d-flex justify-content-start" *ngIf="config.menu?.brand">
            <a href="#" onclick="return false;" [routerLink]="['/']" title="{{config.menu?.brand.label?.text}}">
                <img src="{{config.menu?.brand.label?.image}}" alt="{{config.menu?.brand.label?.text}}"
                    class="progression-studios-default-logo progression-studios-hide-mobile-custom-logo">
            </a>
        </h1>
    </div>
    <div class="col-6 d-flex justify-content-center">
        <nav id="site-navigation-pro" class="nav-menu d-flex justify-content-center" style="background-color: yellow;">
            <ul class="sf-menu">
                <li class="normal-item-pro" *ngFor="let iten of config.menu?.itens"
                    [ngClass]="{'current-menu-item': isSectionActive(iten.route, iten.anchor)}"
                    style="margin-right: 25px;">
                    <a href="" title="{{iten.label?.text}}" onclick="return false;" [routerLink]="[iten.route]"
                        class="{{iten.label?.class}}">
                        {{iten.label?.text}}
                    </a>
                </li>
            </ul>
        </nav>
    </div>
    <div class="col-3 d-flex justify-content-end">
        <nav id="site-navigation-pro" class="nav-menu" style="background-color: greenyellow;">
            <ul class="sf-menu">
                <li class="normal-item-pro" *ngFor="let iten of config.menu?.itensEnd"
                    [ngClass]="{'current-menu-item': isSectionActive(iten.route, iten.anchor)}"
                    style="margin-right: 25px;">
                    <a href="" title="{{iten.label?.text}}" onclick="return false;" [routerLink]="[iten.route]"
                        class="{{iten.label?.class}}">
                    </a>
                </li>
            </ul>
        </nav>

        <button class="btn btn-sm cBtnLang" [ngClass]="{active: language == 'pt'}" type="button"
            (click)="btLang('pt')" title="PT">PT</button>
        <span class="btn btn-sm cBtnLang nopointer">|</span>
        <button class="btn btn-sm cBtnLang" [ngClass]="{active: language == 'en'}" type="button"
            (click)="btLang('en')" title="EN">EN</button>
    </div>
</div> -->
