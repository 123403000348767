declare global {
  interface String {
    vgNormalize: () => string;
    getFileExtension: () => string;
  }
  interface Array<T> {
    vgNormalize: () => Array<T>;
  }

}

(String.prototype as any).getFileExtension = function (): string {
  // tslint:disable-next-line: no-bitwise
  return this.slice((this.lastIndexOf('.') - 1 >>> 0) + 2);
}

// troca chars com acentos pelos sem acentos, passa para lower case remove tudo que não letras e '-', substitui espaços por '-'
String.prototype.vgNormalize = function (): string {
  if (this.normalize) {
    return this.normalize('NFD')
      .replace(/[\u0300-\u036f]/g, '')
      .toLowerCase()
      .replace(/[^a-z0-9\-\s]/gim, '')
      .replace(/\s+/g, '-');
  }
  else {
    const accents = 'ÀÁÂÃÄÅàáâãäåßÒÓÔÕÕÖØòóôõöøÈÉÊËèéêëðÇçÐÌÍÎÏìíîïÙÚÛÜùúûüÑñŠšŸÿýŽž';
    const accentsOut = 'AAAAAAaaaaaaBOOOOOOOooooooEEEEeeeeeCcDIIIIiiiiUUUUuuuuNnSsYyyZz';
    const str = this.split('');
    const strLen = str.length;
    let i: number;
    let x;
    for (i = 0; i < strLen; i++) {
      x = accents.indexOf(str[i]);
      if (x !== -1) {
        str[i] = accentsOut[x];
      }
    }
    return str
      .join('')
      .replace(/[\u0300-\u036f]/g, '')
      .toLowerCase()
      .replace(/[^a-z0-9\-\s]/gim, '')
      .replace(/\s+/g, '-');
  }
};

Array.prototype.vgNormalize = function () {
  const ret: string[] = [];
  this.forEach(element => {
    ret.push(element.vgNormalize());
  });
  return ret;
};

export { }