<div class="container" *ngIf="notFound">
  <div class="row">

    <div class="col-sm-12 margin_top text-center">
      <h2>404</h2>
      <h5>Oops! Page not found</h5>
    </div>

  </div>
</div>

