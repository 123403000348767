import * as _ from 'lodash-es';
import { Injectable, Type } from '@angular/core';
import { Route, Router } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class RouteConfigurationService {

  constructor(private router: Router) {
  }

  public doCreateRoutes(routeConfig: any, tabs: any[], tabsComponentType: any, isBackoffice: boolean, pathToAdjust: string) {
    // replace child routes with the routes for the components tabs
    // console.log('NEW routes:', this.router.config);
    // const routeConfig = this.activatedRoute.snapshot.routeConfig;

    // if (routeConfig.data?.original) {
      // console.log('NEW routes XX');
      const children = routeConfig.children;

      if ((children === undefined || children === null || children.length === 0 || children.length === 1 && children[0].path === '**') && tabs !== null && tabs.length > 0) {
        const childrenRoute: Route[] = [];
        tabs.forEach(tab => {
          const newRoute: Route = {
            path: tab.route,
            component: tabsComponentType,
            children: []
          };

          if (childrenRoute.length === 0) {   // add default route
            childrenRoute.push({ path: '', redirectTo: tab.route, pathMatch: 'full' });
          }
          childrenRoute.push(newRoute);

          if (tab.subtabs) {
            tab.subtabs.forEach((st: any) => {
              const newSubRoute: Route = {
                path: st.route,
                component: tabsComponentType,
                children: []
              };
              //childrenRoute.push(newSubRoute);
              newRoute.children?.push(newSubRoute);
            });
          }
        });

        const myRout = this.router.config; // this.route.routeConfig;

        // if (isBackoffice) {
        //   const rBo = _.find(myRout, ['path', 'bo']);
        //   const r = _.find(rBo?.children, ['path', pathToAdjust]);
        //   // const r = _.find(rBo._loadedConfig.routes, ['path', pathToAdjust]);

        //   // (myRout[1] as any)._loadedConfig.routes[2].children[1].children = childrenRoute;
        //   if (r.children.length > 1)
        //     r.children[1].children = childrenRoute;
        //   else 
        //     r.children = childrenRoute;

        //   // this.routeReuseService.clearAllSavedHandle();
        // } else {
          // (myRout[2] as any).children = childrenRoute;
          // let r = _.find(myRout, ['path', routeConfig.path]);

          // if (!r) {
          //   r = _.find(myRout, ['path', pathToAdjust]);
          //   r = r.children[1];
          // }

          // (r as any).children = childrenRoute;
        // }

        routeConfig.children = childrenRoute;

        this.router.resetConfig(myRout);
        this.router.onSameUrlNavigation = 'reload';

        const urlArray = this.router.url.split('?'); // remove querystring params
        let queryParams = null;
        if ((this.router as any).rawUrlTree != null) {
          queryParams = (this.router as any).rawUrlTree.queryParams;
        }

        //  console.log('d-NEW routes:', this.router.config, this.router.url);
        // console.log('urlArray[0]:', urlArray[0]);

        // este bloco funciona no FO, tanto a navegar como a refrescar F5
        // this.router.navigateByUrl('/', { skipLocationChange: true }).then(() => {
        //   this.router.navigate([urlArray[0]], { queryParams: queryParams });
        // });

        // setTimeout(() => {
        // if (isBackoffice === false) {
        this.router.navigate([urlArray[0]], { queryParams });
        // }
        // }, 1000);

      }
    // }

  }
}
