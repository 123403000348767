import { Component, OnInit } from '@angular/core';
import { Route } from '@angular/router';
import { ActivatedRoute, Router } from '@angular/router';
import { ConfigurationService } from '../../services/configuration.service';
import { DataSourceService } from '../../services/data-source.service';
import { JsonConfigService } from '../../services/json-config.service';
import { CanActivateNavigation } from '../../services/security.service';
import { DynamicComponentsService } from '../dynamic-component/component-lookup';
import { GenericContentComponent } from '../generic-content/generic-content.component';
import { P404Component } from '../p404/p404.component';

@Component({
  selector: 'lib-dynamic-routes',
  templateUrl: './dynamic-routes.component.html',
  styleUrls: ['./dynamic-routes.component.css']
})
export class DynamicRoutesComponent implements OnInit {

  constructor(private router: Router, private activatedRoute: ActivatedRoute, private configurationService: ConfigurationService,
    private jsonConfigService: JsonConfigService, private dataSourceService: DataSourceService,
    private dynamicComponentsService: DynamicComponentsService) { }

  ngOnInit(): void {

    // get main application configuration (json) from the server api
    // create the dynamic routes of the application

    this.configurationService.configurationSubscribe((appConfig: any) => {
      if (appConfig) {
        this.jsonConfigService
          .getApplicationConfig(appConfig.applicationModule)
          .subscribe(conf => {
            if (conf?.routes) {
              this.setRoutes(conf.routes);
            }
          });

        // initialize datasource service
        this.dataSourceService.initDatasourceConfig();
      }
    });
  }

  private setRoutes = (routes: []) => {
    // this.activatedRoute.data.subscribe(datas => {

    const routeConfig = this.router.config;
    //console.log('routeConfig:', routeConfig);
    console.log('routes:', routes);

    const children = routeConfig;

    if ((children === undefined || children === null || children.length === 0 || (children.length >= 2 && children[0].path === '**'))) {

      const nRoute: Route[] = [];

      routes.forEach((rout: any) => {
        // console.log('rout.children:', rout.children);
        if (rout.children) {
          // 
           console.log('rout.component:', rout.component);

          const type = this.dynamicComponentsService.getType(rout.component);
           console.log('type:', type);

          const rparent = { path: rout.path, component: type, data: rout.componentConfiguration, title: rout.title, children: <any>[], canActivate: (rout.allowAnonymous === false ? [CanActivateNavigation] : undefined) };
          // const rparent = { path: rout.path, component: type, data: rout.componentConfiguration, title: rout.title, children: <any>[], canActivate: [CanActivateNavigation] };

          rout.children.forEach((rout1: any) => {
            // console.log('rout1', rout1);

            if (rout1.children) {
              const childrenRoute1: Route[] = [];
              // const rparent1 = { path: rout1.path, component: GenericContentComponent, data: rout1.configuration, title: rout1.title, children: <any>[], canActivate: (rout1.allowAnonymous === false ? [CanActivateNavigation] : undefined) };
              rout1.children.forEach((rout2: any) => {

                if (rout2.children) {
                  const childrenRoute2: Route[] = [];
                  rout2.children.forEach((rout3: any) => {
                    const rparent2 = { path: rout3.path, component: GenericContentComponent, data: rout3.configuration, title: rout2.title, children: <any>[], canActivate: (rout3.allowAnonymous === false ? [CanActivateNavigation] : undefined) };
                    childrenRoute2.push(rparent2);
                  });
                  childrenRoute1.push({
                    path: rout2.path, component: GenericContentComponent, data: rout2.configuration, title: rout2.title, children: childrenRoute2,
                    canActivate: (rout2.allowAnonymous === false ? [CanActivateNavigation] : undefined)
                  });
                }
                else {
                  childrenRoute1.push({
                    path: rout2.path, component: GenericContentComponent, data: rout2.configuration, title: rout2.title, children: <any>[],
                    canActivate: (rout2.allowAnonymous === false ? [CanActivateNavigation] : undefined)
                  });

                }
                // childrenRoute1.push(rparent1);
              });
              childrenRoute1.push({ path: '**', component: P404Component });

              rparent.children.push({
                path: rout1.path, component: GenericContentComponent, data: rout1.configuration, title: rout1.title, children: childrenRoute1,
                canActivate: (rout1.allowAnonymous === false ? [CanActivateNavigation] : undefined)
              });
            }
            else {
              // rparent.children.push({ path: rout1.path, component: GenericContentComponent, data: rout1.configuration, title: rout1.title, canActivate: (rout1.allowAnonymous === false ? [CanActivateNavigation] : undefined) });
              rparent.children.push({ path: rout1.path, component: GenericContentComponent, data: rout1.configuration, title: rout1.title });
            }
          });

          // rparent.children.push({ path: '**', component: P404Component });

          nRoute.push(rparent);

        } else {
          console.log('rota:', rout.path);
          nRoute.push({ path: rout.path, component: GenericContentComponent, data: rout.configuration, title: rout.title, canActivate: (rout.allowAnonymous === false ? [CanActivateNavigation] : undefined) });
        }
      });

      const newRoutes = [...nRoute, children[1]];
      // console.log('d-NEW routes:', JSON.stringify(newRoutes));

      this.router.resetConfig(newRoutes);
      this.router.onSameUrlNavigation = 'reload';
      console.log('this.router.url', this.router.url);
      const urlArray = this.router.url.split('?'); // remove querystring params
      let queryParams = null;
      if ((this.router as any).rawUrlTree != null) {
        queryParams = (this.router as any).rawUrlTree.queryParams;
      }
      this.router.navigate([urlArray[0]], { queryParams });
      // this.router.navigate(['/'], { queryParams });
      console.log('urlArray[0]', urlArray[0]);
      console.log('queryParams', queryParams);
    }

    //   const rotas = this.router.config;

    //  console.log('d-NEW routes:', JSON.stringify(newRoutes));
     //console.log('d-old routes:', this.router.config, this.router.url);

    //  routes.forEach((r: any) => {
    //     r.configuration.rows.forEach((row: any) => {
    //         console.log('d-NEW : ', r.path, JSON.stringify(row);
    //     });
    //  })

    // });
    // }
  }


}
