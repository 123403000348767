import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { DynamicRoutesComponent, LoginComponent } from 'libs/vg-common';
import { VayvoMenuComponent } from './components/vayvo-menu/vayvo-menu.component';
import { VayvoSliderComponent } from './components/vayvo-slider/vayvo-slider.component';
import { VayvoConteudosComponent } from './components/vayvo-conteudos/vayvo-conteudos.component';
import { VayvoViewerComponent } from './components/vayvo-viewer/vayvo-viewer.component';
import { VayvoScrollTopComponent } from './components/vayvo-scroll-top/vayvo-scroll-top.component';
import { P404Component } from './p404/p404.component';
import { VayvoConteudoViewerComponent } from './components/vayvo-conteudo-viewer/vayvo-conteudo-viewer.component';
import { VayvoSearchComponent } from './components/vayvo-search/vayvo-search.component';
import { VayvoResultadosComponent } from './components/vayvo-resultados/vayvo-resultados.component';
import { VayvoEntidadeComponent } from './components/vayvo-entidade/vayvo-entidade.component';
import { VayvoSliderHomeComponent } from './components/vayvo-slider-home/vayvo-slider-home.component';
import { ConteudoTextComponent } from './components/conteudo-text/conteudo-text.component';
import { MarqueeComponent } from './components/marquee/marquee.component';

const routes: Routes = [
  // { path: 'test', component: P404Component }, // , data: { loadSubMenus: true }, // replace dynamicly
  { path: '**', component: DynamicRoutesComponent }, // , data: { loadSubMenus: true }, // replace dynamicly
  { path: '**', component: P404Component }, // , data: { loadSubMenus: true }
  { path: 'dummyLoginComponent', component: LoginComponent },
  { path: 'dummyVayvoSearch', component: VayvoSearchComponent },
  { path: 'dummyVayvoMenu', component: VayvoMenuComponent },
  { path: 'dummyVayvoSlider', component: VayvoSliderComponent },
  { path: 'dummyConteudoText', component: ConteudoTextComponent },
  { path: 'dummyVayvoViewer', component: VayvoViewerComponent },
  { path: 'dummyVayvoConteudoViewer', component: VayvoConteudoViewerComponent },
  { path: 'dummyVayvoConteudos', component: VayvoConteudosComponent },
  { path: 'dummyVayvoScrollTop', component: VayvoScrollTopComponent },
  { path: 'dummyVayvoResultados', component: VayvoResultadosComponent },
  { path: 'dummyVayvoEntidade', component: VayvoEntidadeComponent },
  { path: 'dummyVayvoEntidade', component: VayvoSliderHomeComponent },
  { path: 'dummyVayvoEntidade', component: MarqueeComponent },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {
    initialNavigation: 'enabledBlocking',
    scrollPositionRestoration: 'enabled',
    useHash: false
  })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
