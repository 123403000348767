
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ComponentLookup, DataSourceService } from 'libs/vg-common';

@Component({
  selector: 'app-vayvo-search',
  templateUrl: './vayvo-search.component.html',
  styleUrls: ['./vayvo-search.component.scss'],
  // animations: [
    //   trigger('dialog', [
      //     transition('void => *', [
        //       style({ transform: 'scale3d(.3, .3, .3)' }),
        //       animate(10000)
        //     ]),
        //     transition('* => void', [
          //       animate(10000, style({ transform: 'scale3d(.0, .0, .0)' }))
  //     ])
  //   ])
  // ]
})
@ComponentLookup('VayvoSearchComponent')
export class VayvoSearchComponent implements OnInit {

  public generos = null;
  public duracoes = null;
  public model = { texto: null, teatro: null, danca: null, cinema: null, fluxListen: null, genero: null, duracao: null };

  constructor(private router: Router, private activeModal: NgbActiveModal, private dataSourceService: DataSourceService) { }

  ngOnInit(): void {
    // get reference data
    this.dataSourceService.getData('genero', null).subscribe(data => {
      this.generos = data;
    });

    this.dataSourceService.getData('duracao', null).subscribe(data => {
      this.duracoes = data;
    });
  }

  public btSearch = () => {
    console.log('btSearch', this.model);

    // remove filtros a null
    const oFiltro = {};
    for(let prop in this.model) {
      if((<any>this.model)[prop]) (<any>oFiltro)[prop] = (<any>this.model)[prop];
    }

    // navega para a página de resultados
    const qss = JSON.stringify(oFiltro);

    this.router.navigate(['/resultados'], { queryParams: { filter: qss } });
    this.activeModal.close();
  }

}
