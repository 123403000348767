<!-- <pre style="background-color: white;">config: {{config | json}}</pre>
<hr /> -->

<div class="container-fluid ps-0 pe-0">

    <!-- config-page: {{config | json}}<br/><br/> -->
    <ng-container *ngFor="let row of config?.rows">
        <div class="row ms-0 me-0" class="{{row?.class}}" >
            <div *ngFor="let cell of row?.cols" class="{{cell?.class}} p-0">
                <lib-generic-cell [configuration]="cell.configuration" (isChanged)="ischanged($event)" (blockSaved)="isblockSaved($event)">
                </lib-generic-cell>
            </div>
        </div>
    </ng-container>

</div>
