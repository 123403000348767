export class FileInfo {
  public id?: number;
  public filename?: string;

  public contentType?: string;
  /**
   * For download file
   */
  public content?: Blob;

  /**
   * For upload file
   */
  public contentArrayBuffer?: ArrayBuffer;
  /**
   * For upload file
   */
  public contentArrayBufferBase64?: string;
}
