import { AfterViewInit, Component, OnInit } from '@angular/core';
import { ComponentLookup, WebApiService } from 'libs/vg-common';
import { ConfigurationService } from 'src/app/services/configuration.service';
declare var jQuery: any;

@Component({
  selector: 'app-vayvo-scroll-top',
  templateUrl: './vayvo-scroll-top.component.html',
  styleUrls: ['./vayvo-scroll-top.component.scss']
})
@ComponentLookup('VayvoScrollTopComponent')
export class VayvoScrollTopComponent implements OnInit, AfterViewInit {

  constructor(private configurationService: ConfigurationService) { }

  ngOnInit(): void {
  }
  ngAfterViewInit() {
    if (this.configurationService.isPlatformBrowser) {

      // browser window scroll (in pixels) after which the "back to top" link is shown
      const offset = 150;

      //browser window scroll (in pixels) after which the "back to top" link opacity is reduced
      const offset_opacity = 1200;

      //duration of the top scrolling animation (in ms)
      const scroll_top_duration = 700;

      //grab the "back to top" link
      const $back_to_top = jQuery('#pro-scroll-top');

      //hide or show the "back to top" link
      jQuery(window).scroll(function () {
        (jQuery(window).scrollTop() > offset) ? $back_to_top.addClass('cd-is-visible') : $back_to_top.removeClass('cd-is-visible cd-fade-out');
        if (jQuery(window).scrollTop() > offset_opacity) {
          $back_to_top.addClass('cd-fade-out');
        }
      });

      //smooth scroll to top
      $back_to_top.on('click', function (event: any) {
        event.preventDefault();
        jQuery('body,html').animate({ scrollTop: 0, }, scroll_top_duration
        );
      });
    }
  }
}
