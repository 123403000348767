import { Component, Input, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ComponentLookup, WebApiService } from 'libs/vg-common';
import { ConfigurationService } from 'src/app/services/configuration.service';

@Component({
  selector: 'app-vayvo-entidade',
  templateUrl: './vayvo-entidade.component.html',
  styleUrls: ['./vayvo-entidade.component.scss']
})
@ComponentLookup('VayvoEntidadeComponent')
export class VayvoEntidadeComponent implements OnInit {
  @Input() public config: any = null;
  @Input() public datasource: any = null;
  
  constructor(private titleService: Title, private configurationService: ConfigurationService, private webApiService: WebApiService) { }

  ngOnInit(): void {
  }

  ngOnChanges(): void {
    if (this.datasource) {

      this.titleService.setTitle(this.datasource.nome + ' - Arenes');


      // get images
        if (this.datasource.backgroundImageId) {
          // get the file from the server / or cache
          if (this.configurationService.isPlatformBrowser) {
            this.webApiService.getImageFile(this.datasource.backgroundImageId, 350, 32000).subscribe((data: any) => {
              this.datasource.backgroundImage = data.contentArrayBufferBase64;
            });
          }
        }
        if (this.datasource.avatarImageId) {
          // get the file from the server / or cache
          if (this.configurationService.isPlatformBrowser) {
            this.webApiService.getImageFile(this.datasource.avatarImageId, 350, 32000).subscribe((data: any) => {
              this.datasource.image = data.contentArrayBufferBase64;
            });
          }
        }

        if (this.datasource?.elenco?.lista) {
          // get images
          this.datasource.elenco.lista.forEach((element: any) => {
            if (element.backgroundImageId) {
              // get the file from the server / or cache
              if (this.configurationService.isPlatformBrowser) {
                this.webApiService.getImageFile(element.backgroundImageId, 32000, 350).subscribe((data: any) => {
                  element.backgroundImage = data.contentArrayBufferBase64;
                });
              }
            }
          });
        }

        if (this.datasource?.realizador?.lista) {
          // get images
          this.datasource.realizador.lista.forEach((element: any) => {
            if (element.backgroundImageId) {
              // get the file from the server / or cache
              if (this.configurationService.isPlatformBrowser) {
                this.webApiService.getImageFile(element.backgroundImageId, 32000, 350).subscribe((data: any) => {
                  element.backgroundImage = data.contentArrayBufferBase64;
                });
              }
            }
          });
        }

        if (this.datasource?.promotor?.lista) {
          // get images
          this.datasource.promotor.lista.forEach((element: any) => {
            if (element.backgroundImageId) {
              // get the file from the server / or cache
              if (this.configurationService.isPlatformBrowser) {
                this.webApiService.getImageFile(element.backgroundImageId, 32000, 350).subscribe((data: any) => {
                  element.backgroundImage = data.contentArrayBufferBase64;
                });
              }
            }
          });
        }
      };
  }

  public getImage = (element: any, nitem: number) => {

    if (element) {
      if (nitem == 0) {  // background
        if (element && element.backgroundImageId && element.backgroundImage)
          return ('data:image/jpeg;base64,' + element.backgroundImage);
        else
          return null;

      } else {
        if (element && element.image)
          return ('data:image/jpeg;base64,' + element.image);
        else
          return null;
      }
    }
    else
      return null;
  }
}
