<!-- <div class="container pt-4">
    <div style="font-size: 1rem;" class="text-secondary m-4" *ngIf="isSearching">
        Searching <span *ngIf="isSearching" class="ml-1 spinner-border spinner-border-sm" role="status"
            aria-hidden="true"></span>
    </div>
    <div class="row g-2">
        <ng-container *ngIf="results && results?.lista">
            <div class="mt-4" style="color: white;background-image: none;"><b>{{results.lista.length}}</b>
                {{"search.Results" | transloco }}</div>
            <div class="col-12 col-sm-6 col-md-4 col-lg-3 " *ngFor="let iten of results?.lista">
                <a class="lnk" [routerLink]="['/', 'conteudo', iten.route, 1, 1]">
                    <div [ngStyle]="{ 'background-image': 'url(' + getImage(iten) + ')'}" class="bconteudo"
                        style="height: 250px; background-repeat: no-repeat;background-size: cover;">
                        <div>
                            <span class="fs18">{{iten.title}}</span><br>
                        </div>
                    </div>
                </a>
            </div>
        </ng-container>
    </div>
</div> -->

<div class="container pt-4">
    <div style="font-size: 1rem;" class="text-secondary m-4" *ngIf="isSearching">
        Searching <span *ngIf="isSearching" class="ml-1 spinner-border spinner-border-sm" role="status"
            aria-hidden="true"></span>
    </div>
    <div class="row g-2">
        <ng-container *ngIf="results && results?.lista">
            <div class="mt-4" style="color: white;background-image: none;"><b>{{results.lista.length}}</b>
                {{"search.Results" | transloco }}</div>
        </ng-container>
    </div>
</div>

<app-vayvo-conteudos-lista [lista]="results?.lista" [config]="config"></app-vayvo-conteudos-lista>
