import { Injectable } from "@angular/core";
import { catchError, EMPTY, Observable, shareReplay } from "rxjs";
import * as _ from 'lodash-es';


import { HttpHelperService } from "./http-helper.service";
import { ConfigurationService } from "./configuration.service";

@Injectable()
export class DataSourceService {
    private controllerName: string;

    private configData: any = null;
    private cacheConfiguration: Map<string, any> = new Map<string, any>();

    constructor(private configuration: ConfigurationService, private httpHelper: HttpHelperService) {
        this.controllerName = 'v1/DataSource';
    }

    public initDatasourceConfig = () => {
        this.httpHelper.httpGet(this.controllerName, 'GetConfig').subscribe((data: any) => {
            this.configData = data;
        });
    }

    public isInitialized = (): boolean => {
        return this.configData !== null;
    }

    public getDataParameters = (datasource: string) => {
        let data = null;
        this.configData?.datasource?.forEach((row: any) => {
            const d = _.find(row, ['name', datasource]);
            if (d) data = d;
        });
        return data;
    }

    public getData = (datasource: string, params: any): Observable<any> => {
        let data: any = this.getDataParameters(datasource);
        // console.log('getData', datasource, params);

        // if (!this.configData) {
        //     console.log('setTimeout !!!! ');
        //  //   setTimeout(() => { this.getData(datasource, params);}, 300); // try again in 300 milliseconds
        //     // return;
        // }

        // this.configData?.datasource?.forEach((row: any) => {
        //     const d = _.find(row, ['name', datasource]);
        //     if (d) data = d;
        // });

        console.log('getData', data);
        if (data) {
            let query = ''; // '?name=' + 'headerSlider' + '&top=5';
            if (params) {
                query = '?' + this.serialize(params);
            }
            // console.log('query', query);

            // check for cache
            if (data.cache?.duration) {
                const cacheKey = this.configuration.getLanguage() + '-' + data.controllerName + '/' + data.action + '/' + datasource + query;

                const dtNow = new Date();
                if (this.cacheConfiguration.has(cacheKey) === false ||
                    dtNow.getTime() - this.cacheConfiguration.get(cacheKey).timestamp > data.cache?.duration * 1000) { // in miliseconds cache 
                    const dat = this.httpHelper.httpGet(this.controllerName, data.action + '/' + datasource + query, params).pipe(
                        shareReplay(1),
                        catchError((err: any) => {
                            this.cacheConfiguration.delete(cacheKey);
                            return EMPTY;
                        }));
                    this.cacheConfiguration.set(cacheKey, { dat, timestamp: new Date().getTime() });
                }
                return this.cacheConfiguration.get(cacheKey).dat;

            } else {
                if (data.controllerName && data.action)
                    return this.httpHelper.httpGet(data.controllerName, data.action + '/' + datasource + query, params);
                else
                    return EMPTY;
            }
        } else {

            // console.log('configData 6', this.configData);

            return EMPTY;
        }
    }


    public getDataPost = (datasource: string, params: any): Observable<any> => {
        let data: any = this.getDataParameters(datasource);
        // console.log('getData', datasource, params);

        // console.log('getData', data);
        if (data) {
            let query = ''; // '?name=' + 'headerSlider' + '&top=5';
            if (params) {
                query = '?' + this.serialize(params);
            }
            // console.log('query', query);

            // check for cache
            if (data.cache?.duration) {
                const cacheKey = this.configuration.getLanguage() + '-' + data.controllerName + '/' + data.action + '/' + datasource + query;

                const dtNow = new Date();
                if (this.cacheConfiguration.has(cacheKey) === false ||
                    dtNow.getTime() - this.cacheConfiguration.get(cacheKey).timestamp > data.cache?.duration * 1000) { // in miliseconds cache 
                    const dat = this.httpHelper.httpPost(this.controllerName, data.action + '/' + datasource, params).pipe(
                        shareReplay(1),
                        catchError((err: any) => {
                            this.cacheConfiguration.delete(cacheKey);
                            return EMPTY;
                        }));
                    this.cacheConfiguration.set(cacheKey, { dat, timestamp: new Date().getTime() });
                }
                return this.cacheConfiguration.get(cacheKey).dat;

            } else {
                if (data.controllerName && data.action)
                    return this.httpHelper.httpPost(data.controllerName, data.action + '/' + datasource, params);
                else
                    return EMPTY;
            }
        } else {

            // console.log('configData 6', this.configData);

            return EMPTY;
        }
    }

    public saveData = (datasource: string, params: any, id: any, dataToSave: any): Observable<any> => {
        let data: any = this.getDataParameters(datasource);

        if (data) {
            let query = ''; // '?name=' + 'headerSlider' + '&top=5';
            if (params) {
                query = '?' + this.serialize(params);
            }

            const toPost = JSON.stringify({ id: id, dataToSave: dataToSave });
            return this.httpHelper.httpPost(data.controllerName, 'SaveData/' + datasource + query, toPost);
        }
        else
            return EMPTY;
    }

    public search = (searchFilter: any): Observable<any> => {
        return this.httpHelper.httpPost(this.controllerName, 'Search', searchFilter);
    }

    private serialize = function (obj: any) {
        var str = [];
        for (var p in obj)
            if (obj.hasOwnProperty(p)) {
                str.push(encodeURIComponent(p) + "=" + encodeURIComponent(obj[p]));
            }
        return str.join("&");
    }
}
