<div class="flexslider progression-studios-slider">
    <ul class="slides">
        <!-- [ngClass]="{'progression_studios_animate_left': idx % 3 === 0, 'progression_studios_animate_in': idx % 3 === 1, 'progression_studios_animate_right': idx % 3 === 2}" -->

        <!-- style="transform: translate3d(0px, 0px, 0px) scale3d(1.03548, 1.03548, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg); transform-style: preserve-3d; will-change: transform;"> -->
        <li *ngFor="let iten of datasource?.lista; let idx = index">
            <div class="progression-studios-slider-image-background"
                [routerLink]="iten.route ? ['/', iten.route] : ['.']" [ngClass]="{'pointer': iten.route }"
                [ngStyle]="{ 'background-image': 'url(' + getImage(iten) + ')'}" style="background-size: auto 100%;animation: shrink 8s 2 alternate;">
                <div class="progression-studios-slider-display-table">
                    <div class="progression-studios-slider-vertical-align" style="height: 100%">
                        <div class="d-flex flex-column" style="height: 100%;xbackground-color:bisque">
                            <div class="align-self-start" style="height: 65%;xbackground-color: coral;display: block;"></div>
                            <div class="progression-studios-slider-caption-width" [ngClass]="{'d-flex align-items-end' : config?.sliderTextBottomLeft}" style="height: 35%; xbackground-color: cadetblue;">
                                <div class="progression-studios-slider-caption-align">
                                    
                                    <h2 [ngClass]="{'sliderTextBottomLeft barlowRegular' : config?.sliderTextBottomLeft}" [innerHTML]="iten.title"></h2>

                                    <!-- <h2 class="sliderTextBottomLeft barlowRegular">
                                        <span class="stb1">PROGRAMA<br/></span><span class="stb2">FORMAÇÃO MODULAR<br/></span><span class="barlowBold stb3">REINVENTA<br/>O TEU PAPEL</span>
                                    </h2> -->
                                    

                                    <div class="clearfix"></div>
                                    <div class="progression-studios-slider-excerpt" *ngIf="iten.sinopse">{{getTruncate(iten.sinopse, 150)}}</div>
                                    <a class="btn btn-slider-pro afterglow" *ngIf="iten.video" [routerLink]="['/', iten.route]"><i class="fas fa-play-circle"></i>{{'slider.Watch Now' | transloco }}</a>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
                <!-- <div class="progression-studios-skrn-slider-upside-down" style="background-image:url(assets/images/Gray1700x1133.png);"></div> -->
            </div>
            <!-- <p style="color: black;">{{iten.backgroundImageId}}</p> -->
            <!-- <pre>{{iten | json}}</pre>  -->
            <!-- <ng-container *ngIf="iten.conteudoId === 7">
                {{downloadFile(31)}}
            </ng-container> -->
        </li>
    </ul>
    
    <!-- <div class="custom-navigation-arrow" *ngIf="!config?.sliderVertical">
        <div class="custom-controls-container-arrow">
            <ul class="flex-direction-nav">
                <li class="flex-nav-next" style="display: none;"><a class="flex-next" style="opacity: 1!important;" href="#">XXXX</a></li>
            </ul>

        </div>
    </div> -->
    <div class="custom-navigation">
        <div class="custom-controls-container">
            <ul class="flex-direction-nav" *ngIf="config?.sliderVertical" style="background-color: aliceblue;">
                <li class="flex-nav-prev" style="display: none;"><a class="flex-prev"
                        style="opacity: 0!important;display: none;" href="#"></a></li>
                <li class="flex-nav-next" style="display: none;"><a class="flex-next" xstyle="opacity: 1!important;"
                        href="#"></a></li>
            </ul>
        </div>
    </div>

</div>

<!-- <pre>config: {{config | json}}</pre> -->