<div id="video-page-title-pro" class="video-embed-height-adjust pointer" *ngIf="datasource"
    [ngStyle]="{ 'background-image': (getImage(datasource, 0) ? 'url(' + getImage(datasource, 0) + ')' : null)}">

    <div id="video-embedded-container">
        <div style="padding:26.25% 0 0 0;position:relative;" *ngIf="datasource.backgroundImage"></div>
    </div>
    <div id="video-page-title-gradient-base"></div>
</div>

<div class="container custom-gutters-pro" *ngIf="datasource">
    <div id="xvideo-post-container" style="position: relative;">
        <h1 class="video-post-heading-title"><img class="vayvo-video-cast-image-detail" *ngIf="datasource.image"
                [src]="getImage(datasource, 1)" alt="{{datasource.nome}}" /> {{datasource.nome}}</h1>
        <div class="clearfix"></div>
        <div id="vayvo-video-post-content" *ngIf="datasource.descricao">
            <p style="text-align: justify;" [innerHTML]="datasource.descricao"></p>
        </div>
    </div>
    <div class="clearfix"></div>

    <div *ngIf="datasource?.elenco?.lista?.length > 0" class="row" style="margin-top: 36px">
        <h3>{{"entity.Participations" | transloco }}</h3>
        <div class="mt-4" style="color: white;background-image: none;"><b>{{datasource?.elenco.lista?.length}}</b>
            {{"search.Results" | transloco }}</div>
        <div class="col-12 col-sm-6 col-md-4 col-lg-3 " *ngFor="let iten of datasource?.elenco?.lista">
            <a [routerLink]="['/', this.config.route, iten.route, '1', '1']">
                <div [ngStyle]="{ 'background-image': 'url(' + getImage(iten, 0) + ')'}" class="bconteudo"
                    style="height: 300px; background-repeat: no-repeat;background-size: cover;">
                    <div>
                        <span class="fs22">{{iten.title}}</span><br>
                        <span class="fs15">
                            <ng-container *ngFor="let at of iten.attributes">{{at}} </ng-container>
                        </span>
                    </div>
                </div>
            </a>
        </div>
    </div>



    <div *ngIf="datasource?.realizador?.lista?.length > 0" class="row" style="margin-top: 36px">
        <h3>{{"entity.Directors" | transloco }}</h3>
        <div class="mt-4" style="color: white;background-image: none;"><b>{{datasource?.realizador.lista?.length}}</b>
            {{"search.Results" | transloco }}</div>
        <div class="col-12 col-sm-6 col-md-4 col-lg-3 " *ngFor="let iten of datasource?.realizador?.lista">
            <a [routerLink]="['/', this.config.route, iten.route, '1', '1']">
                <div [ngStyle]="{ 'background-image': 'url(' + getImage(iten, 0) + ')'}" class="bconteudo"
                    style="height: 300px; background-repeat: no-repeat;background-size: cover;">
                    <div>
                        <span class="fs22">{{iten.title}}</span><br>
                        <span class="fs15">
                            <ng-container *ngFor="let at of iten.attributes">{{at}} </ng-container>
                        </span>
                    </div>
                </div>
            </a>
        </div>
    </div>

    <div *ngIf="datasource?.promotor?.lista?.length > 0" class="row" style="margin-top: 36px">
        <h3>{{"entity.Promotor" | transloco }}</h3>
        <div class="mt-4" style="color: white;background-image: none;"><b>{{datasource?.promotor.lista?.length}}</b>
            {{"search.Results" | transloco }}</div>
        <div class="col-12 col-sm-6 col-md-4 col-lg-3 " *ngFor="let iten of datasource?.promotor?.lista">
            <a [routerLink]="['/', this.config.route, iten.route, '1', '1']">
                <div [ngStyle]="{ 'background-image': 'url(' + getImage(iten, 0) + ')'}" class="bconteudo"
                    style="height: 300px; background-repeat: no-repeat;background-size: cover;">
                    <div>
                        <span class="fs22">{{iten.title}}</span><br>
                        <span class="fs15">
                            <ng-container *ngFor="let at of iten.attributes">{{at}} </ng-container>
                        </span>
                    </div>
                </div>
            </a>
        </div>
    </div>
</div>


<!-- datasource: {{datasource | json}} -->